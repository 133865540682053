import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
//import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Swiper styles
//import "swiper/css";

//Images
import pic1 from "./../../assets/images/testimonials/pic1.jpg";
import pic2 from "./../../assets/images/testimonials/pic2.jpg";
import pic3 from "./../../assets/images/testimonials/pic3.jpg";

// import Swiper core and required modules
import { Autoplay, Navigation, Pagination } from "swiper";

//SwiperCore.use([EffectCoverflow,Pagination]);

const dataBlog = [
  {
    image: pic1,
    title: "Johan Lee",
    info: `<p>
  - Đã có trên 200 bài viết về âm nhạc Việt Nam và âm nhạc Châu Á đã được đăng trong các tạp chí chuyên môn của nhiều nước, đặc biệt những bài viết cho Courrier de l’UNESCO được dịch ra 14 ngôn ngữ khác nhau.<br />
  - Đã thực hiện trên 25 đĩa hát 33 vòng và CD về âm nhạc Việt Nam và vài nước Châu Á.<br />
  - Đã lồng tiếng cho trên 100 phim từ tiếng Anh ra tiếng Pháp, diễn viên điện ảnh cho 6 phim quảng cáo tại Pháp và làm diễn viên cho 2 phim lớn là: La Rivière des trois Joncques (Pháp) và A town like Alice (Anh).<br />
  - Viết bài về âm nhạc truyền thống Việt Nam tại nhiều báo chí, tạp chí trong nước và trên thế giới. Giúp các cơ quan trong nước giới thiệu âm nhạc Việt Nam tại các Hội nghị quốc tế, trong các Diễn đàn âm nhạc Châu Á, các Cơ quan quốc tế như: UNESCO, Hội đồng âm nhạc quốc tế.<br />
  - Giới thiệu các bộ môn Ca trù, Múa rối nước, Hát bội miền Nam, Nhã nhạc (Cung đình Huế), Nhạc Phật giáo Việt Nam, đàn Tài tử, ca nhạc Huế bằng những buổi thuyết trình, đĩa hát, băng video, làm cho ngoại quốc hiểu thêm về các di sản văn hóa phi vật thể của Việt Nam.
</p>`,
  },
  {
    image: pic2,
    title: "Lee Jordon",
    info: `<p>
  + 1949 : Tại Budapest Hung-ga-ri trong cuộc thi về nhạc khí dân tộc do Liên hoan Thanh Niên tổ chức : Giới thiệu 2 nhạc khí đờn Cò và đờn Tranh Việt Nam trong những bài bản Đờn ca tài tử, giải nhì thế giới.<br />
  + 1949 - 1951 : Giới thiệu trong nhiều dịp cho các đoàn thể Thanh niên Pháp và những người yêu nhạc Đông phương những chương trình về Đờn ca tài tử, được tổ chức trong các nhà Văn hoá Pháp.<br />
  + 1953 : Bắt đầu soạn Luận án Tiến sĩ văn chương khoa Nhạc học tại Trường Đại học Văn khoa Paris – Sorbonne với đề tài “âm nhạc truyền thống Dân tộc Việt Nam”, bảo vệ thành công năm 1958 – Ưu hạng với lời khen của Ban giám khảo. In ra năm 1962 với sự ủng hộ tài chánh của Trung tâm quốc gia nghiên cứu khoa học Pháp và Bảo tàng viện Guimet. Tuy là một luận án về âm nhạc truyền thống nói chung nhưng các tư liệu phong phú nhứt có liên quan đến âm nhạc Cung đình Huế và âm nhạc Tài tử miền Nam.<br />
</p>`,
  },
  {
    image: pic2,
    title: "Lee Jordon",
    info: `<p>
  + 1959 : Thành lập Trung tâm Học nhạc Đông phương chuyên dạy các môn âm nhạc truyền thống châu Á theo phương pháp truyền khẩu và truyền ngón. Trong Trung tâm đó lãnh trách nhiệm Giám đốc Nghệ thuật và chuyên dạy Đờn ca tài tử (Tranh, Kìm, Cò) với sự cộng tác của hai con (Trần Quang Hải &amp; Trần Thị Thuỷ Ngọc), hoạt động 30 năm và đào tạo trên 150 sinh viên Pháp và quốc tế biết nhạc tài tử, trong số đó nổi bật nhứt là 3 sinh viên : Kim Lý (dạy đờn Tranh cho trẻ em Việt Nam nhiều nơi tại Pháp) – Andre Lantz (GS điện tử, đờn Tranh rất mùi, đi nghĩa vụ quân sự tại châu Phi, nhân ngày Tết Việt Nam đã đánh đàn bài Tứ đại làm rơi nước mắt bao nhiêu kiều bào Việt ở châu Phi) – Nelly Felz (GS dạy cấp Tú tài chuyên về âm nhạc, bắt buộc tất cả sinh viên phải biết đờn Tranh mới được thêm điểm trong cuộc thi, chuyên Đờn ca tài tử và đặc biệt thích nhứt là hơi Xuân và hơi Oán).<br />
</p>`,
  },
  {
    image: pic3,
    title: "Alex Costa",
    info: `<p>
  + 1959 - 1963 : Được tuyển vào Trung tâm Quốc gia Nghiên cứu Khoa học Pháp với cấp bậc “Tuỳ viên Nghiên cứu” để nghiên cứu về ngôn ngữ âm nhạc Việt Nam đặc biệt trong Đờn ca tài tử và các cách chuyển hệ (Metabole).<br />
  + 1959 - 1965 : Giới thiệu Đờn ca tài tử trong tiết mục dành riêng cho tôi tại 2 hiệu ăn lớn : hiệu ăn La Table du Mandarin của anh Phạm Văn Mười và hiệu ăn Bồng Lai của anh Bùi Văn Tuyền tại Vùng Champs-Elysees.<br />
  + 1963 - 1967 : So sánh âm nhạc Đờn ca tài tử với âm nhạc thính phòng của nhiều nước khác trên châu Á (Trung Quốc – Nhật Bản – Triều Tiên - Ấn Độ).
  Nhờ bà De Chambure trong lúc thăm Việt Nam bà có máy ghi âm tối tân bằng âm thanh nổi hiệu Nagra ghi âm Đờn ca tài tử dưới sự chỉ đạo của GS Nguyễn Hữ Ba và nhờ tiền tài trợ của Trung tâm Nghiên cứu Học nhạc với phương pháp đối chiếu tại Tây Bá Linh làm ra được 1 dĩa hát về nhạc Cung đình Huế và 1 dĩa hát về âm nhạc tài tử miền Nam, xuất bản dưới nhãn hiệu dĩa hát của Unesco, trong dĩa đó có những bài Tứ đại oán và Vọng cổ do Bạch Huệ ca, trong dàn đờn người giữ cây đờn Kìm là bác Sáu Tửng.<br />
</p>`,
  },
  {
    image: pic3,
    title: "Alex Costa",
    info: `<p>
  + 1968 - 1972 : Chức vụ Nghiên cứu sư, đã được mời dạy nhạc học trong chuyên khoa Dân tộc nhạc học. Trong những năm này đặt trọng tâm nghiên cứu vào các Kịch nghệ châu Á : Jing Xi (kinh kịch Trung Quốc), Nôh, Kabuki (Nhật Bản), Pansori (Triều Tiên), Hát chèo, hát bội, hát cải lương (Việt Nam) <br />
  + 1972 : Trường Đại học Carbondale Mỹ có mời Nhạc sư Vĩnh Bảo, tôi và Nhạc sĩ Phạm Duy sang dạy một khoá 3 tháng cho sinh viên. NS Vĩnh Bảo dạy về cách đóng đàn và cách đờn theo truyền thống Đờn ca tài tử, tôi dạy về Lịch sử và lý thuyết của âm nhạc Việt Nam nói chung và Đờn ca tài tử nói riêng, Phạm Duy dạy về Dân ca Việt Nam (cả 3 miền). Trường tổ chức nhiều buổi hoà nhạc Đờn ca tài tử cho sinh viên, kể cả các tổ chức Văn hoá của vùng do NS Vĩnh Bảo và tôi đảm nhận.<br />
</p>`,
  },
  {
    image: pic3,
    title: "Alex Costa",
    info: `<p>
  + 1973 : Trước khi trở về Việt Nam NS Vĩnh Bảo ghé Pháp và ở tại nhà tôi. Hai anh em góp sức giảng dạy về Đờn ca tài tử cho Trường Đại học Sorbonne và những Trường Trung học cấp III ở vùng ngoại ô.
  Trung tâm học nhạc Đông phương tổ chức nhiều buổi hoà nhạc cho Việt kiều, trong dịp này hãng dĩa Ocora (thuộc Đài phát thanh Paris) mời NS Vĩnh Bảo và tôi ghi âm một dĩa hát Đờn ca tài tử. Các chuyên gia Unesco sau khi nghe dĩa hát Ocora rất thích và phái một chuyên gia ghi âm Hubert de Frayssex thực hiện một dĩa hát đặc biệt do Unesco tài trợ và được phát hành dưới nhãn hiệu dĩa hát của Unesco, mục “Musical Sources” (Nguồn gốc âm nhạc) đây là dĩa giới thiệu Đờn ca tài tử : NS Vĩnh Bảo đờn Tranh, tôi đờn Tỳ Bà 2 bản Tây Thi cổ bản. NS Vĩnh Bảo độc tấu 3 bài Nam Xuân, Nam Ai, Đảo ngũ cung và một phần bài Tứ đại oán, có đủ cả phần rao đầu.<br />
  + 2010 : Cố vấn đặc biệt cho Uỷ Ban Thành lập Hồ sơ về Đờn ca tài tử để gửi Unesco. Đã góp ý và sửa những sai lầm trong các cuộn phim về Đờn ca tài tử của Hồ sơ. Cung cấp những dĩa hát mang nhãn hiệu Unesco, trong đó có nhiều tiết mục Đờn ca tài tử.
</p>`,
  },
];

const TestimonialSlider = () => {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  return (
    <>
      <Swiper
        className="testimonial-swiper"
        speed={1500}
        //parallax= {true}
        slidesPerView={"auto"}
        spaceBetween={0}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 3000,
        }}
        modules={[Autoplay, Navigation, Pagination]}
        onSwiper={(swiper) => {
          setTimeout(() => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
            swiper.navigation.destroy();
            swiper.navigation.init();
            swiper.navigation.update();
          });
        }}
      >
        {dataBlog.map((d, i) => (
          <SwiperSlide key={i}>
            <div className="testimonial-1">
              <div
                className="testimonial-text"
                dangerouslySetInnerHTML={{ __html: d.info }}
              ></div>
            </div>
          </SwiperSlide>
        ))}
        <div className="swiper-btn container swiper-btn-center-lr">
          <div
            className="test-swiper-prev btn-prev style-1"
            ref={navigationPrevRef}
          >
            <i className="fa fa-arrow-left-long"></i>
          </div>
          <div
            className="test-swiper-next btn-next style-1"
            ref={navigationNextRef}
          >
            <i className="fa fa-arrow-right-long"></i>
          </div>
        </div>
      </Swiper>
    </>
  );
};

export default TestimonialSlider;

import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import Header2 from "../layouts/Header2";

import ScrollToTop from "./../layouts/ScrollToTop";
import Header from "./../layouts/Header";
import Footer from "./../layouts/Footer";
import Home from "./Home";
import Home2 from "./Home2";
import Home3 from "./Home3";
import AboutUs from "./AboutUs";
import Volunteer from "./Volunteer";
import BecomeVolunteer from "./BecomeVolunteer";
import Faq from "./Faq";
import Certificates from "./Certificates";
import AskQuestion from "./AskQuestion";
import HappyClients from "./HappyClients";
import HowItWorks from "./HowItWorks";
import Mission from "./Mission";
import TermsCondition from "./TermsCondition";
import Error from "./Error";
import UnderMaintenance from "./UnderMaintenance";
import ComingSoon from "./ComingSoon";
import BrowseFundraiser from "./BrowseFundraiser";
import BecomeFundraiser from "./BecomeFundraiser";
import FundraiserDetail from "./FundraiserDetail";
import Project from "./Project";
import ProjectCategories from "./ProjectCategories";
import ProjectSidebar from "./ProjectSidebar";
import ProjectStory from "./ProjectStory";
import Blog from "./Blog";
import BlogGrid from "./BlogGrid";
import BlogList from "./BlogList";
import BlogDetails from "./BlogDetails";
import ContactUs from "./ContactUs";
import { IMAGES } from "../constant/theme";
import Introduction from "./Introduction";
import Footer2 from "../layouts/Footer2";
import News from "./News";
import Partner from "./Partner";
import Sponsor from "./Sponsor";
import Activity from "./Activity";

function Index() {
  return (
    <>
      <Routes>
        <Route path="/error-404" exact element={<Error />} />
        {/* <Route path="/under-maintenance" exact element={<UnderMaintenance />} />
        <Route path="/coming-soon" exact element={<ComingSoon />} />
        <Route path="/index-2" exact element={<Home2 />} />
        <Route path="/index-3" exact element={<Home3 />} /> */}
        <Route element={<MainLayout />}>
          <Route path="/" exact element={<Home2 />} />
          {/* <Route path="/about-us" exact element={<AboutUs />} />
          <Route path="/volunteer" exact element={<Volunteer />} />
          <Route
            path="/become-a-volunteer"
            exact
            element={<BecomeVolunteer />}
          /> */}
          {/* <Route path="/faq" exact element={<Faq />} />
          <Route path="/certificates" exact element={<Certificates />} />
          <Route path="/ask-a-question" exact element={<AskQuestion />} />
          <Route path="/happy-clients" exact element={<HappyClients />} />
          <Route path="/how-it-works" exact element={<HowItWorks />} />
          <Route path="/mission" exact element={<Mission />} /> */}
          <Route path="/news" exact element={<News />} />
          <Route path="/partners" exact element={<Partner />} />
          <Route path="/sponsor" exact element={<Sponsor />} />
          <Route path="/activity" exact element={<Activity />} />
          {/* <Route
            path="/terms-and-condition"
            exact
            element={<TermsCondition />}
          />
          <Route
            path="/browse-fundraiser"
            exact
            element={<BrowseFundraiser />}
          />
          <Route
            path="/become-a-fundraiser"
            exact
            element={<BecomeFundraiser />}
          /> */}
          {/* <Route
            path="/fundraiser-detail"
            exact
            element={<FundraiserDetail />}
          />
          <Route path="/project" exact element={<Project />} />
          <Route
            path="/project-categories"
            exact
            element={<ProjectCategories />}
          /> */}
          {/* <Route path="/project-sidebar" exact element={<ProjectSidebar />} /> */}
          <Route path="/bio" exact element={<ProjectStory />} />
          <Route path="/info" exact element={<Introduction />} />
          {/* <Route path="/blog" exact element={<Blog />} />
          <Route path="/blog-grid" exact element={<BlogGrid />} />
          <Route path="/blog-list" exact element={<BlogList />} />
          <Route path="/blog-details" exact element={<BlogDetails />} />
          <Route path="/contact-us" exact element={<ContactUs />} /> */}
          <Route path="*" exact element={<Error />} />
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
}

function MainLayout() {
  return (
    <div className="page-wraper">
      {/* <Header /> */}
      <Header2 logoStyle={IMAGES.logo2} />

      <Outlet />
      <Footer2 />
    </div>
  );
}
export default Index;

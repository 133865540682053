import React from 'react';
import {Link} from 'react-router-dom';
import donateIcon from "../assets/icons/gstvk/donate.png";
import donateIcon1 from "../assets/icons/gstvk/donate1.png";
import donateIcon2 from "../assets/icons/gstvk/donate2.png";
import donateIcon3 from "../assets/icons/gstvk/donate3.png";
import donateIcon4 from "../assets/icons/gstvk/donate4.png";

const year2019 = [
    {name:"Trần Trọng Thức", description: "800.000", icon: donateIcon},
    {name:"Nguyễn Thế Thanh", description: "11.000.000", icon: donateIcon2},
    {name:"P.D", description: "45.000.000", icon: donateIcon3},
];

const year2020 = [
    {name:"Đại học Văn Lang", description: "1.240.000.000", icon: donateIcon1},
    {name:"Lê Quốc Ân", description: "50.000.000", icon: donateIcon4},
];

const year2021 = [
    {name:"Dương Trọng Dật", description: "10.000.000", icon: donateIcon},
];

const year2022 = [
    {name:"Trần Tuyết Nga", description: "50.000.000",icon: donateIcon1},
    {name:"Châu Hoài Phương", description: "2.000.000", icon: donateIcon3},
];

const year2023 = [
    {name:"Quỹ Thiện Tâm", description: "100.000.000", icon: donateIcon},
    {name:"Võ Trọng Nam", description: "30.000.000", icon: donateIcon1},
    {name:"Võ Hiếu Dân", description: "15.000.000", icon: donateIcon2},
    {name:"Nguyễn Thế Thanh", description: "15.000.000", icon: donateIcon3},
    {name:"Lê Quốc Ân", description: "10.000.000", icon: donateIcon4},
];

const Donation = () => {
    return (
        <>
        <div className="section-head text-center">
            <h5 className="sub-title">CÁ NHÂN</h5>
            <h3 className="title">Thống kê Quyên góp</h3>
            {/* <p>Năm 2019</p> */}
        </div>
        <h4 className="title" style={{marginBottom: '20px'}}>Năm 2019</h4>  
        <div className="row" style={{justifyContent: 'center'}}>
            <div className="col-xl-3 col-lg-4 col-sm-6" key={0}>
                <div className="icon-bx-wraper style-1 text-center m-b30" style={{height: '86%'}}>
                    <div className="icon-lg m-b30"> <Link className="icon-cell" style={{cursor: 'default'}}>
                        {/* <i className={item.icon}></i> */}
                        <img src={donateIcon1} alt="Icon" />
                    </Link> </div>
                    <div className="icon-content">
                        <h5 className="dz-tilte text-capitalize"><Link to={"/project-categories"}>Trương Hòa Bình</Link></h5>
                        <p>50.000.000<br/><i style={{fontSize: '12px'}}>Nguyên Phó Thủ tướng Thường trực Chính phủ</i></p>
                    </div>
                </div>
            </div>
            {year2019.map((item, index)=>(
                    <div className="col-xl-3 col-lg-4 col-sm-6" key={index + 1}>
                        <div className="icon-bx-wraper style-1 text-center m-b30" style={{height: '86%'}}>
                            <div className="icon-lg m-b30"> <Link className="icon-cell" style={{cursor: 'default'}}>
                                {/* <i className={item.icon}></i> */}
                                <img src={item.icon} alt="Icon" />
                            </Link> </div>
                            <div className="icon-content">
                                <h5 className="dz-tilte text-capitalize"><Link to={"/project-categories"}>{item.name}</Link></h5>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    </div>
                ))} 
        </div>   
        <h4 className="title" style={{marginBottom: '20px'}}>Năm 2020</h4> 
        <div className="row" style={{justifyContent: 'center'}}>
            {year2020.map((item, index)=>(
                    <div className="col-xl-3 col-lg-4 col-sm-6" key={index}>
                        <div className="icon-bx-wraper style-1 text-center m-b30" style={{height: '86%'}}>
                            <div className="icon-lg m-b30"> <Link className="icon-cell" style={{cursor: 'default'}}>
                                {/* <i className={item.icon}></i> */}
                                <img src={item.icon} alt="Icon" />
                            </Link> </div>
                            <div className="icon-content">
                                <h5 className="dz-tilte text-capitalize"><Link to={"/project-categories"}>{item.name}</Link></h5>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    </div>
                ))} 
        </div>
        <h4 className="title" style={{marginBottom: '20px'}}>Năm 2021</h4> 
        <div className="row" style={{justifyContent: 'center'}}>
            {year2021.map((item, index)=>(
                    <div className="col-xl-3 col-lg-4 col-sm-6" key={index}>
                        <div className="icon-bx-wraper style-1 text-center m-b30" style={{height: '86%'}}>
                            <div className="icon-lg m-b30"> <Link className="icon-cell" style={{cursor: 'default'}}>
                                {/* <i className={item.icon}></i> */}
                                <img src={item.icon} alt="Icon" />
                            </Link> </div>
                            <div className="icon-content">
                                <h5 className="dz-tilte text-capitalize"><Link to={"/project-categories"}>{item.name}</Link></h5>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    </div>
                ))} 
        </div>
        <h4 className="title" style={{marginBottom: '20px'}}>Năm 2022</h4> 
        <div className="row" style={{justifyContent: 'center'}}>
            {year2022.map((item, index)=>(
                    <div className="col-xl-3 col-lg-4 col-sm-6" key={index}>
                        <div className="icon-bx-wraper style-1 text-center m-b30" style={{height: '86%'}}>
                            <div className="icon-lg m-b30"> <Link className="icon-cell" style={{cursor: 'default'}}>
                                {/* <i className={item.icon}></i> */}
                                <img src={item.icon} alt="Icon" />
                            </Link> </div>
                            <div className="icon-content">
                                <h5 className="dz-tilte text-capitalize"><Link to={"/project-categories"}>{item.name}</Link></h5>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    </div>
                ))} 
        </div>
        <h4 className="title" style={{marginBottom: '20px'}}>Năm 2023</h4> 
        <div className="row" style={{justifyContent: 'center'}}>
            {year2023.map((item, index)=>(
                    <div className="col-xl-3 col-lg-4 col-sm-6" key={index}>
                        <div className="icon-bx-wraper style-1 text-center m-b30" style={{height: '86%'}}>
                            <div className="icon-lg m-b30"> <Link className="icon-cell" style={{cursor: 'default'}}>
                                {/* <i className={item.icon}></i> */}
                                <img src={item.icon} alt="Icon" />
                            </Link> </div>
                            <div className="icon-content">
                                <h5 className="dz-tilte text-capitalize"><Link to={"/project-categories"}>{item.name}</Link></h5>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    </div>
                ))} 
        </div>    
        </>
    );
};



export default Donation;
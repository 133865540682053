import React from "react";
import { Link } from "react-router-dom";

import baner from "../assets/images/main-slider/slider1/slider-bg1.jpg";
import logowhite from "../assets/images/logo-white-2.png";
import logo from "./../assets/images/gstvk/newLogo.png";
import fessiorLogo from "./../assets/images/gstvk/fessior-1.png";
import shape4 from "../assets/images/side-images/shape4.png";
import line4 from "../assets/images/side-images/shape-line4.png";
import shape5 from "../assets/images/side-images/shape5.png";
import line5 from "../assets/images/side-images/shape-line5.png";
import location from "../assets/images/footer-location.png";
import gstvk10 from "../assets/images/gstvk/gstvk10.jpg";

const Footer2 = () => {
  return (
    <>
      <footer className="site-footer style-2" id="footer">
        <div
          className="footer-top footer-feature background-luminosity"
          style={{ backgroundImage: "url(" + gstvk10 + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 ">
                <div className="widget widget_about">
                  <div className="footer-logo logo-white fessior">
                    <Link to={"/"}>
                      <img src={logo} alt="" style={{ maxWidth: "120px" }} />
                    </Link>
                    {/* <Link to={"/"} style={{display: 'flex', alignItems: 'center'}}><img src={fessiorLogo} alt="" style={{maxWidth: '120px'}} /></Link>  */}
                  </div>
                  <p>
                    Quỹ Trần Văn Khê hoạt động theo nguyên tắc không vì lợi
                    nhuận, tự nguyện, tự tạo vốn, tự trang trải kinh phí và tự
                    chịu trách nhiệm trước pháp luật bằng tài sản của Quỹ.
                  </p>
                  <div className="dz-social-icon style-3">
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          className="fab fa-facebook-f"
                          rel="noreferrer"
                          href="https://www.facebook.com/quytranvankhe"
                        ></a>
                      </li>
                      <li>
                        <a
                          className="fa-solid fa-envelope"
                          rel="noreferrer"
                          href="mailto: tranvankhefoundation@gmail.com"
                        ></a>
                      </li>
                      {/* <li><a target="_blank" className="fab fa-twitter"  rel="noreferrer" href="https://twitter.com/"></a></li>
                                            <li><a target="_blank" className="fab fa-youtube"  rel="noreferrer" href="https://youtube.com/"></a></li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6 ">
                <div className="widget widget_services">
                  <h4 className="widget-title">Thông tin</h4>
                  <div className="separator-2 bg-secondary m-b30"></div>
                  <ul>
                    <li>
                      <Link to={"/info"}>Giới thiệu Quỹ</Link>
                    </li>
                    <li>
                      <Link to={"/bio"}>Tiểu sử</Link>
                    </li>
                    <li>
                      <Link to={"/news"}>Tin tức</Link>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/quytranvankhe"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Fanpage
                      </a>
                    </li>
                    {/* <li><Link to={"/certificates"}>Certificates</Link></li>
                                        <li><Link to={"/terms-and-condition"}>Terms And Condition</Link></li> */}
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-md-4 col-sm-6 ">
                <div className="widget recent-posts-entry">
                  <h4 className="widget-title">Tin tức</h4>
                  <div className="separator-2 bg-secondary m-b30"></div>
                  <div className="widget-post-bx">
                    <div className="widget-post clearfix">
                      <div className="dz-info">
                        <h6 className="title">
                          <a
                            href="https://nld.com.vn/van-nghe/nghe-si-nghe-nhan-han-hoan-tham-du-le-trao-giai-thuong-va-hoc-bong-tran-van-khe-20230723062617233.htm"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Nghệ sĩ, nghệ nhân hân hoan tham dự lễ trao "Giải
                            thưởng và học bổng Trần Văn Khê"
                          </a>
                        </h6>
                        <span className="post-date"> 23 tháng 7, 2023</span>
                      </div>
                    </div>
                    <div className="post-separator"></div>
                    <div className="widget-post clearfix">
                      <div className="dz-info">
                        <h6 className="title">
                          <a
                            href="https://tuoitre.vn/chang-trai-khiem-thi-thoi-sao-truc-nhan-hoc-bong-tran-van-khe-2023-20230723091006869.htm?gidzl=CAizLe2uC1uwlJrMYwOv4J2uF5oMzp8GV-WwN97mPHj_w6rLnQ9Y4dYwR5J2gJ4IBBHeNMI8Apn-Yx0s7W"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Chàng trai khiếm thị thổi sáo trúc nhận học bổng
                            Trần Văn Khê 2023
                          </a>
                        </h6>
                        <span className="post-date">23 tháng 7, 2023</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-4 col-sm-12 ">
                <div className="widget widget_locations">
                  <h4 className="widget-title">Liên hệ</h4>
                  <div className="separator-2 bg-secondary m-b30"></div>
                  <div className="clearfix">
                    <h6 className="title">Đại diện truyền thông</h6>
                    <p>
                      Bà Nguyễn Thế Thanh
                      <br /> Email: nbthethanh@gmail.com
                    </p>
                    <h6 className="title">Liên hệ Quỹ</h6>
                    <p>
                      tranvankhefoundation@gmail.com
                      <br />
                      Thư ký: Ngọc Hân 0782 78 78 28 (zalo, viber)
                    </p>
                    {/* <img src={location} alt="" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img className="shape1" src={shape4} alt="" />
          <img className="shape2" src={line4} alt="" />
          <img className="shape3" src={shape5} alt="" />
          <img className="shape4" src={line5} alt="" />
        </div>

        <div className="footer-bottom text-center">
          <div className="container">
            <span className="copyright-text">
              Website Quỹ học bổng Trần Văn Khê - © 2023 bởi{" "}
              <a
                href="https://www.facebook.com/dscxhcmut"
                rel="noreferrer"
                target="_blank"
              >
                Fessior Community
              </a>
            </span>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer2;

import React from 'react';
import {Link} from 'react-router-dom';

import bg from '../assets/images/gstvk/gstvk10.jpg';

const Error = () => {
    return (
        <>
            <div className="error-page" style={{backgroundImage: "url("+ bg + ")"}}>
                <div className="error-inner text-center">
                    <div className="dz-error">404</div>
                    <h2 className="error-head">Xin thứ lỗi. Nhưng trang bạn đang tìm hiện không tồn tại</h2>
                    <Link to={"/"} className="btn btn-secondary">VỀ TRANG CHỦ</Link>
                </div>
            </div>
        </>
    );
};


export default Error;
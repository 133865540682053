import React from "react";
import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
//import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Swiper styles
//import "swiper/css";

//Images
import project1 from "./../../assets/images/project/project1/pic1.jpg";
import project2 from "./../../assets/images/project/project1/pic2.jpg";
import project3 from "./../../assets/images/gstvk/info.jpg";
import project4 from "./../../assets/images/project/project1/pic4.jpg";

import avat1 from "./../../assets/images/avatar/avatar1.jpg";
import avat2 from "./../../assets/images/avatar/avatar2.jpg";
import avat3 from "./../../assets/images/avatar/avatar3.jpg";
import blog9 from "./../../assets/images/gstvk/blog9.jpg";
import blog10 from "./../../assets/images/gstvk/blog10.jpg";
import blog11 from "./../../assets/images/gstvk/blog11.jpg";
import blog12 from "./../../assets/images/gstvk/blog12.jpg";

// import Swiper core and required modules
import { Autoplay } from "swiper";

//SwiperCore.use([EffectCoverflow,Pagination]);

// const dataBlog = [
// 	{
//         title:"Cố Giáo sư Trần Văn Khê: Người “truyền lửa” tình yêu âm nhạc dân tộc",
//         subtitle:"BÁO DÂN TỘC VÀ PHÁT TRIỂN",
//         image: blog2,
//         image2:avat1,
//         autor:"Nguyệt Anh",
//         progres:"50%",
//         date: "27 tháng 7, 2021",
//         description: "Cố Giáo sư Trần Văn Khê (1921 - 2015) đã dành gần trọn cả cuộc đời cống hiến cho nền âm nhạc dân tộc. Ông cũng là người đưa âm nhạc Việt Nam có mặt trên bản đồ âm nhạc thế giới.",
//         link: "https://baodantoc.vn/co-giao-su-tran-van-khe-nguoi-truyen-lua-tinh-yeu-am-nhac-dan-toc-1627352336837.htm"
//     },
// 	{
//         title:"Thực hiện di nguyện cố GS-TS Trần Văn Khê",
//         subtitle:"BÁO LAO ĐỘNG",
//         image: blog3,
//         image2: avat2,
//         autor:"Thanh Hiệp",
//         progres:"70%",
//         date: "17 tháng 5, 2021",
//         description: "Nhân kỷ niệm 100 năm ngày sinh cố GS-TS Trần Văn Khê (24.7.1921 - 24.7.2021), nhóm thân hữu Trần Văn Khê và lãnh đạo Trường Đại học Văn Lang (nơi chịu trách nhiệm thành lập quỹ học bổng theo di nguyện của cố GS-TS Trần Văn Khê) sẽ công bố quyết định thành lập quỹ học bổng mang tên ông.",
//         link: "https://nld.com.vn/van-nghe/thuc-hien-di-nguyen-co-gs-ts-tran-van-khe-20210516204231668.htm"
//     },
// 	{
//         title:"Quỹ học bổng Trần Văn Khê ra đời đúng 100 năm ngày sinh của ông",
//         subtitle:"BÁO THANH NIÊN",
//         image: blog4,
//         image2: avat3,
//         autor:"Lê Công Sơn",
//         progres:"90%",
//         date: "8 tháng 3, 2021",
//         description: "UBND TP.HCM quyết định cấp giấy phép thành lập và công nhận điều lệ quỹ học bổng mang tên Giáo sư - nhạc sĩ Trần Văn Khê. Theo đó, Quỹ học bổng Trần Văn Khê chịu sự quản lý nhà nước của Sở GD-ĐT TP.HCM, đồng thời có tư cách pháp nhân, con dấu và tài khoản riêng, tự đảm bảo kinh phí, phương tiện hoạt động.",
//         link: "https://thanhnien.vn/quy-hoc-bong-tran-van-khe-ra-doi-dung-100-nam-ngay-sinh-cua-ong-1851043777.htm"
//     },
// 	{
//         title:"Sẽ khởi động Quỹ Trần Văn Khê",
//         subtitle:"BAO TUỔI TRẺ",
//         image: blog1,
//         image2: avat1,
//         autor:"Linh Đoan",
//         progres:"60%",
//         date: "25 tháng 7, 2020",
//         description: "Sáng 24-7 tại TP.HCM, Trường ĐH Văn Lang và Ban thân hữu Trần Văn Khê đã tổ chức chương trình kỷ niệm 99 năm ngày sinh cố GS.TS Trần Văn Khê (24-7-1921 - 24-7-2020) trong không khí trang trọng và ấm áp.",
//         link: "https://tuoitre.vn/se-khoi-dong-quy-tran-van-khe-20200725083558894.htm"
//     },

// ];

const dataBlog = [
  {
    title:
      'Nghệ sĩ, nghệ nhân hân hoan tham dự lễ trao "Giải thưởng và học bổng Trần Văn Khê"',
    subtitle: "BÁO NGƯỜI LAO ĐỘNG",
    image: blog9,
    image2: avat1,
    autor: "Thanh Hiệp",
    progres: "50%",
    date: "23 tháng 7, 2023",
    description:
      'Sáng 23-7, rất đông văn nghệ sĩ, các nghệ nhân của nhiều bộ môn nghệ thuật dân tộc đã đến tham dự lễ trao "Giải thưởng và học bổng Trần Văn Khê" tại Nhà hát TP HCM.',
    link: "https://nld.com.vn/van-nghe/nghe-si-nghe-nhan-han-hoan-tham-du-le-trao-giai-thuong-va-hoc-bong-tran-van-khe-20230723062617233.htm",
  },
  {
    title:
      "Tôn vinh giá trị âm nhạc Việt qua giải thưởng và học bổng Trần Văn Khê",
    subtitle: "BÁO PHỤ NỮ",
    image: blog10,
    image2: avat2,
    autor: "Trang Thư",
    progres: "70%",
    date: "23 tháng 7, 2023",
    description:
      'Sáng 23/7, lễ trao "Giải thưởng và học bổng Trần Văn Khê" đã diễn ra tại Nhà hát TPHCM với sự tham gia của nhiều nghệ sĩ, nghệ nhân của nhiều bộ môn nghệ thuật dân tộc.',
    link: "https://www.phunuonline.com.vn/ton-vinh-gia-tri-am-nhac-viet-qua-giai-thuong-va-hoc-bong-tran-van-khe-a1497073.html",
  },
  {
    title: "Chàng trai khiếm thị thổi sáo trúc nhận học bổng Trần Văn Khê 2023",
    subtitle: "BÁO TUỔI TRẺ",
    image: blog11,
    image2: avat3,
    autor: "Thái Thái",
    progres: "90%",
    date: "23 tháng 7, 2023",
    description:
      "Là một trong những học sinh, sinh viên đầu tiên nhận học bổng, Nguyễn Đức Thiện (sinh năm 2000) là người khiếm thị bẩm sinh, mất thị lực hoàn toàn.",
    link: "https://tuoitre.vn/chang-trai-khiem-thi-thoi-sao-truc-nhan-hoc-bong-tran-van-khe-2023-20230723091006869.htm?gidzl=CAizLe2uC1uwlJrMYwOv4J2uF5oMzp8GV-WwN97mPHj_w6rLnQ9Y4dYwR5J2gJ4IBBHeNMI8Apn-Yx0s7W",
  },
  {
    title: "9 gương mặt trẻ nhận học bổng Trần Văn Khê",
    subtitle: "BÁO VNEXPRESS",
    image: blog12,
    image2: avat1,
    autor: "Mai Nhật",
    progres: "60%",
    date: "23 tháng 7, 2023",
    description:
      "9 gương mặt được trao học bổng mỗi người 10 triệu đồng, gồm các bạn trẻ đến từ Nhạc viện TP HCM, Học viện Âm nhạc Quốc gia, Học viện Âm nhạc Huế.",
    link: "https://vnexpress.net/9-guong-mat-tre-nhan-hoc-bong-tran-van-khe-4632895.html?gidzl=gSpFRuAkSIUZsizlYR1uAAdVpc6KuNCjve_8OPFc92RcZvzenxGZB-7Tdcd0lt0ljTEQO6GJMez-YQPt9m",
  },
];

const TrendingSlider = () => {
  return (
    <div className="home-blog-swiper">
      <Swiper
        className="recent-blog2"
        speed={1500}
        //parallax= {true}
        slidesPerView={4}
        spaceBetween={30}
        loop={true}
        autoplay={{
          delay: 3000,
        }}
        modules={[Autoplay]}
        breakpoints={{
          1680: {
            slidesPerView: 4,
          },
          1200: {
            slidesPerView: 3,
          },
          991: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1,
          },
        }}
      >
        {dataBlog.map((d, i) => (
          <SwiperSlide key={i}>
            <div className="dz-card style-5">
              <div className="dz-media">
                <a href={d.link} rel="noreferrer" target="_blank">
                  <img src={d.image} alt="" />
                </a>
              </div>
              <div className="dz-info">
                <ul className="dz-category">
                  <li>
                    <Link to={"#"} style={{ cursor: "default" }}>
                      {d.subtitle}
                    </Link>
                  </li>
                </ul>
                <h5 className="dz-title home-blog-title">
                  <a href={d.link} rel="noreferrer" target="_blank">
                    {d.title}{" "}
                  </a>
                </h5>
                <ul className="dz-meta">
                  <li className="author-wrappper author-wrappper-sm mt-0">
                    <div className="author-media" style={{ marginRight: 0 }}>
                      {/* <img src={d.image2} alt="" />  */}
                      <i
                        class="fa-solid fa-pen-nib"
                        style={{ color: "rgb(27, 130, 113)" }}
                      ></i>
                    </div>
                    <span className="home-blog-span">{d.autor}</span>
                  </li>
                  <li
                    className="dz-date"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <i
                      className="fa-solid fa-calendar"
                      style={{ marginRight: "6px" }}
                    ></i>{" "}
                    <span className="home-blog-span">{d.date}</span>
                  </li>
                </ul>
                <p>{d?.description}</p>
                {/* <div className="progress-bx style-2">
                                    <div className="progress">
                                        <div className="progress-bar progress-bar-primary" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style={{width:d.progres}}>
                                            <span className="progress-value">{d.progres}</span>
                                        </div>
                                    </div>
                                    <ul className="progress-tag">
                                        <li className="raised">
                                            <p>Raised: <span className="text-primary">$ 5,345</span></p>
                                        </li>
                                        <li className="goal">
                                            <p>Goal: <span className="text-primary">$70,000</span></p>
                                        </li>
                                    </ul> 
                                </div> */}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TrendingSlider;

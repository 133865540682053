import React from "react";
import { Link } from "react-router-dom";

import PageBanner from "../layouts/PageBanner";

//images
import bg from "../assets/images/gstvk/gstvk20.jpg";
import avat1 from "../assets/images/avatar/avatar1.jpg";
import avat2 from "../assets/images/avatar/avatar2.jpg";
import avat3 from "../assets/images/avatar/avatar3.jpg";
import writer from "../assets/images/gstvk/creative.png";
import blog1 from "../assets/images/gstvk/blog1.webp";
import blog2 from "../assets/images/gstvk/blog2.jpg";
import blog3 from "../assets/images/gstvk/blog3.jpg";
import blog4 from "../assets/images/gstvk/blog4.jpg";
import blog5 from "../assets/images/gstvk/blog5.jpg";
import blog6 from "../assets/images/gstvk/blog6.jpg";
import blog7 from "../assets/images/gstvk/blog7.jpg";
import blog8 from "../assets/images/gstvk/blog8.jpg";
import blog9 from "../assets/images/gstvk/blog9.jpg";
import blog10 from "../assets/images/gstvk/blog10.jpg";
import blog11 from "../assets/images/gstvk/blog11.jpg";
import blog12 from "../assets/images/gstvk/blog12.jpg";
import blog13 from "../assets/images/gstvk/blog13.jpg";
import shape2 from "../assets/images/pattern/shape2.png";
import shape3 from "../assets/images/pattern/shape3.png";
import shape5 from "../assets/images/pattern/shape5.png";
import shape1 from "../assets/images/pattern/shape1.png";
import shape6 from "../assets/images/pattern/shape6.png";

// import {cardDetials} from './Blog';
import UpdateBlog from "../components/Home/UpdateBlog";

const dataBlog = [
  {
    title:
      'Nghệ sĩ, nghệ nhân hân hoan tham dự lễ trao "Giải thưởng và học bổng Trần Văn Khê"',
    subtitle: "BÁO NGƯỜI LAO ĐỘNG",
    image: blog9,
    image2: writer,
    autor: "Thanh Hiệp",
    progres: "50%",
    date: "23 tháng 7, 2023",
    description:
      'Sáng 23-7, rất đông văn nghệ sĩ, các nghệ nhân của nhiều bộ môn nghệ thuật dân tộc đã đến tham dự lễ trao "Giải thưởng và học bổng Trần Văn Khê" tại Nhà hát TP HCM.',
    link: "https://nld.com.vn/van-nghe/nghe-si-nghe-nhan-han-hoan-tham-du-le-trao-giai-thuong-va-hoc-bong-tran-van-khe-20230723062617233.htm",
  },
  {
    title:
      "Tôn vinh giá trị âm nhạc Việt qua giải thưởng và học bổng Trần Văn Khê",
    subtitle: "BÁO PHỤ NỮ",
    image: blog10,
    image2: writer,
    autor: "Trang Thư",
    progres: "70%",
    date: "23 tháng 7, 2023",
    description:
      'Sáng 23/7, lễ trao "Giải thưởng và học bổng Trần Văn Khê" đã diễn ra tại Nhà hát TPHCM với sự tham gia của nhiều nghệ sĩ, nghệ nhân của nhiều bộ môn nghệ thuật dân tộc.',
    link: "https://www.phunuonline.com.vn/ton-vinh-gia-tri-am-nhac-viet-qua-giai-thuong-va-hoc-bong-tran-van-khe-a1497073.html",
  },
  {
    title: "Chàng trai khiếm thị thổi sáo trúc nhận học bổng Trần Văn Khê 2023",
    subtitle: "BÁO TUỔI TRẺ",
    image: blog11,
    image2: writer,
    autor: "Thái Thái",
    progres: "90%",
    date: "23 tháng 7, 2023",
    description:
      "Là một trong những học sinh, sinh viên đầu tiên nhận học bổng, Nguyễn Đức Thiện (sinh năm 2000) là người khiếm thị bẩm sinh, mất thị lực hoàn toàn.",
    link: "https://tuoitre.vn/chang-trai-khiem-thi-thoi-sao-truc-nhan-hoc-bong-tran-van-khe-2023-20230723091006869.htm?gidzl=CAizLe2uC1uwlJrMYwOv4J2uF5oMzp8GV-WwN97mPHj_w6rLnQ9Y4dYwR5J2gJ4IBBHeNMI8Apn-Yx0s7W",
  },
  {
    title: "9 gương mặt trẻ nhận học bổng Trần Văn Khê",
    subtitle: "BÁO VNEXPRESS",
    image: blog12,
    image2: writer,
    autor: "Mai Nhật",
    progres: "60%",
    date: "23 tháng 7, 2023",
    description:
      "9 gương mặt được trao học bổng mỗi người 10 triệu đồng, gồm các bạn trẻ đến từ Nhạc viện TP HCM, Học viện Âm nhạc Quốc gia, Học viện Âm nhạc Huế.",
    link: "https://vnexpress.net/9-guong-mat-tre-nhan-hoc-bong-tran-van-khe-4632895.html?gidzl=gSpFRuAkSIUZsizlYR1uAAdVpc6KuNCjve_8OPFc92RcZvzenxGZB-7Tdcd0lt0ljTEQO6GJMez-YQPt9m",
  },
  {
    title: "LỄ TRAO TẶNG HỌC BỔNG VÀ GIẢI THƯỞNG TRẦN VĂN KHÊ NĂM 2023",
    subtitle: "HTV TIN TỨC",
    image: blog13,
    image2: writer,
    autor: "HTV Tin tức",
    progres: "60%",
    date: "23 tháng 7, 2023",
    description:
      "Sự kiện trao học bổng và giải thưởng Trần Văn Khê nằm trong khuôn khổ hoạt động và cam kết của Quỹ học bổng Trần Văn Khê. Đây còn là dịp hội ngộ, giao lưu những tấm lòng luôn tự hào, trân trọng và yêu quý âm nhạc dân tộc từ ba miền Bắc-Trung-Nam.",
    link: "https://youtu.be/tyX9T-rbhFk",
  },
  {
    title:
      "Cố Giáo sư Trần Văn Khê: Người “truyền lửa” tình yêu âm nhạc dân tộc",
    subtitle: "BÁO DÂN TỘC VÀ PHÁT TRIỂN",
    image: blog2,
    image2: writer,
    autor: "Nguyệt Anh",
    progres: "50%",
    date: "27 tháng 7, 2021",
    description:
      "Cố Giáo sư Trần Văn Khê (1921 - 2015) đã dành gần trọn cả cuộc đời cống hiến cho nền âm nhạc dân tộc. Ông cũng là người đưa âm nhạc Việt Nam có mặt trên bản đồ âm nhạc thế giới.",
    link: "https://baodantoc.vn/co-giao-su-tran-van-khe-nguoi-truyen-lua-tinh-yeu-am-nhac-dan-toc-1627352336837.htm",
  },
  {
    title: "Thực hiện di nguyện cố GS-TS Trần Văn Khê",
    subtitle: "BÁO LAO ĐỘNG",
    image: blog3,
    image2: writer,
    autor: "Thanh Hiệp",
    progres: "70%",
    date: "17 tháng 5, 2021",
    description:
      "Nhân kỷ niệm 100 năm ngày sinh cố GS-TS Trần Văn Khê (24.7.1921 - 24.7.2021), nhóm thân hữu Trần Văn Khê và lãnh đạo Trường Đại học Văn Lang (nơi chịu trách nhiệm thành lập quỹ học bổng theo di nguyện của cố GS-TS Trần Văn Khê) sẽ công bố quyết định thành lập quỹ học bổng mang tên ông.",
    link: "https://nld.com.vn/van-nghe/thuc-hien-di-nguyen-co-gs-ts-tran-van-khe-20210516204231668.htm",
  },
  {
    title: "Quỹ học bổng Trần Văn Khê ra đời đúng 100 năm ngày sinh của ông",
    subtitle: "BÁO THANH NIÊN",
    image: blog4,
    image2: writer,
    autor: "Lê Công Sơn",
    progres: "90%",
    date: "8 tháng 3, 2021",
    description:
      "UBND TP.HCM quyết định cấp giấy phép thành lập và công nhận điều lệ quỹ học bổng mang tên Giáo sư - nhạc sĩ Trần Văn Khê. Theo đó, Quỹ học bổng Trần Văn Khê chịu sự quản lý nhà nước của Sở GD-ĐT TP.HCM, đồng thời có tư cách pháp nhân, con dấu và tài khoản riêng, tự đảm bảo kinh phí, phương tiện hoạt động.",
    link: "https://thanhnien.vn/quy-hoc-bong-tran-van-khe-ra-doi-dung-100-nam-ngay-sinh-cua-ong-1851043777.htm",
  },
  {
    title: "Sẽ khởi động Quỹ Trần Văn Khê",
    subtitle: "BÁO TUỔI TRẺ",
    image: blog1,
    image2: writer,
    autor: "Linh Đoan",
    progres: "60%",
    date: "25 tháng 7, 2020",
    description:
      "Sáng 24-7 tại TP.HCM, Trường ĐH Văn Lang và Ban thân hữu Trần Văn Khê đã tổ chức chương trình kỷ niệm 99 năm ngày sinh cố GS.TS Trần Văn Khê (24-7-1921 - 24-7-2020) trong không khí trang trọng và ấm áp.",
    link: "https://tuoitre.vn/se-khoi-dong-quy-tran-van-khe-20200725083558894.htm",
  },
  {
    title:
      "Nghệ sĩ Kim Cương, Thành Lộc xúc động trong kỷ niệm ngày sinh cố giáo sư Trần Văn Khê",
    subtitle: "BÁO NGƯỜI LAO ĐỘNG",
    image: blog5,
    image2: writer,
    autor: "Thanh Hiệp",
    progres: "60%",
    date: "24 tháng 7, 2020",
    description:
      "Sáng 24-7, Đại học Văn Lang và nhóm thân hữu Trần Văn Khê đã tổ chức kỷ niệm 99 năm ngày sinh của cố GS.TS Trần Văn Khê.",
    link: "https://nld.com.vn/van-nghe/nghe-si-cuong-thanh-loc-xuc-dong-trong-ky-niem-ngay-sinh-co-giao-su-tran-van-khe-20200724142237538.htm",
  },
  {
    title: "Tháng bảy mở hộp kỷ niệm, thắp lên ước nguyện Trần Văn Khê",
    subtitle: "TẠP CHÍ NGƯỜI ĐÔ THỊ",
    image: blog6,
    image2: writer,
    autor: "Trâm Anh",
    progres: "60%",
    date: "25 tháng 7, 2020",
    description:
      "Đông đảo sinh viên, giới nghệ sĩ, trí thức đã có mặt trong chương trình kỷ niệm 99 năm ngày sinh cố Giáo sư Trần Văn Khê do Đại học Văn Lang và Nhóm thân hữu Trần Văn Khê phối hợp tổ chức diễn ra vào sáng ngày 24.7.2020 tại Hội trường Trịnh Công Sơn (Trường Đại học Văn Lang).",
    link: "https://nguoidothi.net.vn/thang-bay-mo-hop-ky-niem-thap-len-uoc-nguyen-tran-van-khe-24561.html",
  },
  {
    title:
      "Nhớ mãi Giáo sư Trần Văn Khê – người trọn đời cống hiến cho âm nhạc truyền thống",
    subtitle: "BÁO DÂN VIỆT",
    image: blog7,
    image2: writer,
    autor: "Minh Thi",
    progres: "60%",
    date: "24 tháng 7, 2020",
    description:
      "NSND Kim Cương, NSƯT Thành Lộc, nhà thiết kế Sĩ Hoàng, tiến sĩ Nguyễn Nhã, nhà nghiên cứu Nguyễn Đắc Xuân dành những lời yêu thương, kính trọng cho cố GSTS Trần Văn Khê.",
    link: "https://danviet.vn/nho-mai-giao-su-tran-van-khe-nguoi-tron-doi-cong-hien-cho-am-nhac-truyen-thong-20200724124735859.htm",
  },
  {
    title:
      "NSND Kim Cương, NSƯT Thành Lộc và những kỷ niệm không quên với cố Giáo sư Trần Văn Khê",
    subtitle: "BÁO PHỤ NỮ",
    image: blog8,
    image2: writer,
    autor: "Trung Sơn",
    progres: "60%",
    date: "24 tháng 7, 2020",
    description:
      "5 năm sau ngày tạ thế, cố Giáo sư Trần Văn Khê vẫn sống trong lòng người ở lại. Sáng 24/7, lễ kỷ niệm 99 năm ngày sinh của ông được nhóm thân hữu Trần Văn Khê và trường Đại học Văn Lang phối hợp tổ chức.",
    link: "https://www.phunuonline.com.vn/nsnd-kim-cuong-nsut-thanh-loc-va-nhung-ky-niem-khong-quen-voi-co-giao-su-tran-van-khe-a1413678.html",
  },
];

const News = () => {
  return (
    <>
      <div className="page-content bg-white">
        <div
          className="dz-bnr-inr dz-bnr-inr-sm style-1 text-center"
          style={{ backgroundImage: "url(" + bg + ")" }}
        >
          <div className="container">
            <div className="dz-bnr-inr-entry">
              {/* <h1>HỌC BỔNG GIẢI THƯỞNG TRẦN VĂN KHÊ 2023</h1> */}
              {/* <nav aria-label="breadcrumb" className="breadcrumb-row">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/"}>{maintitle}</Link></li>
                                <li className="breadcrumb-item active">{pagetitle}</li>
                            </ul>
                        </nav> */}
            </div>
          </div>
        </div>
        <section
          className="content-inner bg-light section-pattren1"
          style={{ paddingTop: "50px", paddingBottom: "20px" }}
        >
          <div className="container">
            <div className="section-head text-center">
              <h2 className="title">TIN TỨC</h2>
            </div>
          </div>
          {/* <img src={shape1} className="shape-1 move-1" alt="shape" /> */}
          <img src={shape2} className="shape-2 move-2" alt="shape" />
          <img src={shape3} className="shape-3 move-1" alt="shape" />
          <img src={shape5} className="shape-4 rotating" alt="shape" />
          {/* <img src={shape6} className="shape-5 rotating" alt="shape" /> */}
          <img src={shape6} className="shape-6 rotating" alt="shape" />
        </section>
        <section className="content-inner-2" style={{ marginBottom: "40px" }}>
          <div className="container">
            <div className="row">
              {dataBlog.map((item, ind) => (
                <div className="col-xl-4 col-md-6 m-b30" key={ind}>
                  <div
                    className="dz-card style-1"
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="dz-media">
                      <Link to={"#"}>
                        <img src={item.image} alt="" />
                      </Link>
                      <ul className="dz-badge-list">
                        <li>
                          <Link
                            to={"#"}
                            className="dz-badge"
                            style={{ cursor: "default" }}
                          >
                            {item.subtitle}
                          </Link>
                        </li>
                      </ul>
                      <a href={item.link} className="btn btn-secondary">
                        Đọc thêm
                      </a>
                    </div>
                    <div
                      className="dz-info"
                      style={{
                        flexGrow: "1",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <h5 className="dz-title home-blog-title">
                        <a href={item.link}>{item.title} </a>
                      </h5>
                      <p>{item.description}</p>
                      <div
                        className="author-wrappper"
                        style={{ marginTop: "auto" }}
                      >
                        <div
                          className="author-media"
                          style={{
                            width: "40px",
                            height: "40px",
                            minWidth: "40px",
                          }}
                        >
                          <img src={item.image2} alt="" />
                        </div>
                        <div className="author-content">
                          <div className="author-head">
                            <h6 className="author-name">{item.autor}</h6>
                          </div>
                          <ul className="author-meta">
                            <li className="date">{item.date}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* <div className="row">		
                            <div className="col-12 m-sm-t0 m-t30">		
                                <nav  className="pagination-bx">
                                    <div className="page-item">
                                        <Link to={"#"} className="page-link prev"><i className="fas fa-chevron-left"></i></Link>
                                    </div>
                                    <ul className="pagination">
                                        <li className="page-item"><Link to={"#"} className="page-link">1</Link></li>
                                        <li className="page-item"><Link to={"#"} className="page-link active">2</Link></li>
                                        <li className="page-item"><Link to={"#"} className="page-link">3</Link></li>
                                        <li className="page-item"><Link to={"#"} className="page-link">4</Link></li>
                                    </ul>
                                    <div className="page-item">
                                        <Link to={"#"} className="page-link next"><i className="fas fa-chevron-right"></i></Link>
                                    </div>
                                </nav>
                            </div>
                        </div> */}
          </div>
        </section>
        {/* <div className="call-action style-1 content-inner-1">
          <div className="container">
            <UpdateBlog />
          </div>
        </div> */}
      </div>
    </>
  );
};

export default News;

import React from "react";
import { Link } from "react-router-dom";

import PageBanner from "../layouts/PageBanner";
import CountUp from "react-countup";

import TestimonialSlider from "../components/Home/TestimonialSlider";
import UpdateBlog from "../components/Home/UpdateBlog";

import bg from "../assets/images/banner/bnr3.jpg";
import large4 from "../assets/images/project/large/pic4.jpg";
import large5 from "../assets/images/project/large/pic5.jpg";
import large6 from "../assets/images/project/large/pic6.jpg";

import avat1 from "../assets/images/avatar/avatar1.jpg";
import avat2 from "../assets/images/avatar/avatar2.jpg";
import avat3 from "../assets/images/avatar/avatar3.jpg";
import gstvk12 from "../assets/images/gstvk/gstvk12.jpg";
import gstvk19 from "../assets/images/gstvk/gstvk19.jpg";
import shape2 from "../assets/images/pattern/shape2.png";
import shape3 from "../assets/images/pattern/shape3.png";
import shape5 from "../assets/images/pattern/shape5.png";
import shape1 from "../assets/images/pattern/shape1.png";
import shape6 from "../assets/images/pattern/shape6.png";

const ProjectStory = () => {
  function isScrolledIntoView(elem) {
    const spliBox = document.querySelectorAll(elem);
    spliBox.forEach(myFunction);
    function myFunction(item, index) {
      console.log("splitbox", item);
      const docViewTop = window.scrollY;
      const docViewBottom = docViewTop + window.innerHeight;
      let elemTop = item.getBoundingClientRect().top + docViewTop;
      const elemBottom = elemTop + item.offsetHeight;
      if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
        item.classList.add("split-active");
      }
    }
  }
  window.addEventListener("scroll", () => {
    isScrolledIntoView(".split-box");
  });
  return (
    <>
      <div className="page-content bg-white">
        <div
          className="dz-bnr-inr dz-bnr-inr-sm style-1 text-center"
          style={{ backgroundImage: "url(" + gstvk12 + ")" }}
        >
          <div className="container">
            <div className="dz-bnr-inr-entry">
              {/* <h1>HỌC BỔNG GIẢI THƯỞNG TRẦN VĂN KHÊ 2023</h1> */}
              {/* <nav aria-label="breadcrumb" className="breadcrumb-row">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/"}>{maintitle}</Link></li>
                                <li className="breadcrumb-item active">{pagetitle}</li>
                            </ul>
                        </nav> */}
            </div>
          </div>
        </div>
        <section
          className="content-inner bg-light section-pattren1"
          style={{ paddingTop: "50px", paddingBottom: "20px" }}
        >
          <div className="container">
            <div className="section-head text-center">
              <h2 className="title">TIỂU SỬ GS.TS TRẦN VĂN KHÊ</h2>
            </div>
          </div>
          {/* <img src={shape1} className="shape-1 move-1" alt="shape" /> */}
          <img src={shape2} className="shape-2 move-2" alt="shape" />
          <img src={shape3} className="shape-3 move-1" alt="shape" />
          <img src={shape5} className="shape-4 rotating" alt="shape" />
          {/* <img src={shape6} className="shape-5 rotating" alt="shape" /> */}
          <img src={shape6} className="shape-6 rotating" alt="shape" />
        </section>
        <section className="content-inner-2">
          <div className="container">
            <div className="row align-items-center project-bx left m-b50">
              <div className="col-lg-6 col-md-12 m-b30">
                <div className="dz-media">
                  <div>
                    <img
                      src="https://cdnimg.vietnamplus.vn/uploaded/pcfo/2015_06_29/2906_tran_van_khe2.jpg"
                      alt=""
                      className="app-1"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 m-b30">
                <h3 className="m-b20">Thông tin chung</h3>
                <p className="m-b0">
                  <b style={{ color: "black" }}>Ngày sinh</b> : 24-07-1921{" "}
                  <br />
                  <b style={{ color: "black" }}>Ngày mất</b> : 24-06-2015 <br />
                  <b style={{ color: "black" }}>Quê quán</b> : Làng Vĩnh Kim,
                  quận Châu Thành, tỉnh Mỹ Tho.
                  <br /> + Được cấp bằng Tiến sĩ Văn chương, Khoa Âm Nhạc học,
                  ĐH Sorbonne Paris (1958).
                  <br /> + Được công nhận chức danh Giáo sư năm 1967.
                </p>
                {/* <div className="fund-count m-t20 m-b25">
                  <h2 className="text-primary">
                    ${" "}
                    <span className="counter">
                      <CountUp end="24553852.24" separator="," decimals="2" />
                    </span>
                  </h2>
                  <h6>Total funded</h6>
                </div> */}
                {/* <div className="author-wrappper">
                  <div className="author-media">
                    <img src={avat1} alt="" />
                  </div>
                  <div className="author-content">
                    <div className="author-head">
                      <h6 className="author-name">Hendric Anderson</h6>
                      <ul className="rating-list">
                        <li>
                          <i className="fa fa-star"></i>
                        </li>{" "}
                        <li>
                          <i className="fa fa-star"></i>
                        </li>{" "}
                        <li>
                          <i className="fa fa-star"></i>
                        </li>{" "}
                        <li>
                          <i className="fa fa-star gray-light"></i>
                        </li>{" "}
                        <li>
                          <i className="fa fa-star gray-light"></i>
                        </li>
                      </ul>
                    </div>
                    <ul className="author-meta">
                      <li className="campaign">12 Campaign</li>
                      <li className="location">New York, London</li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="row align-items-center project-bx right m-b50">
              <div className="col-lg-6 col-md-12 m-b30">
                <h3 className="m-b20">Quá trình học tập/công tác</h3>
                <p className="m-b0">
                  ● <strong>1941-1944 :</strong> Trường Đại học Đông Dương Hà
                  Nội, Y khoa.
                  <br />● <strong>1944-1949 :</strong> Giáo sư tư thục tại Sài
                  Gòn và Hậu Giang ; Ký giả Báo Thần Chung và Việt báo.
                  <br />● <strong>1949-1988 :</strong> Học tập, nghiên cứu và
                  tham gia giảng dạy tại hơn 20 Trường Đại học trên khắp thế
                  giới.
                  <br />● <strong>1959-1988 :</strong> Giám đốc Nghiên cứu tại
                  Trung Tâm Quốc gia Pháp Nghiên cứu Khoa học (Directeur de
                  Recherche au Centre National de la Recherche Scientifique)
                  <br />● <strong>1969-1988 :</strong> Giáo sư Khoa Dân tộc Nhạc
                  học tại Đại học Sorbonne Paris.
                  <br />● <strong>1999-2003 :</strong> Giáo sư Đại học Dân lập
                  Hùng Vương (Tp.HCM).
                  <br />● <strong>2006 đến nay :</strong> Giáo sư Đại học Dân
                  lập Bình Dương. Cố vấn đặc biệt Hội đồng Khoa học Đại học Bình
                  Dương.
                  <br />
                  &emsp;Thành viên của nhiều Hội quốc tế về Âm nhạc và nghiên
                  cứu âm nhạc như : Hội đồng âm nhạc quốc tế thuộc UNESCO, Hội
                  đồng quốc tế âm nhạc truyền thống, Hội đồng quốc tế Giáo dục
                  âm nhạc, Hội đồng quốc tế Nghiên cứu âm nhạc, Hội âm nhạc học,
                  Dân tộc âm nhạc học, Hội nhà văn (Pháp), Hội Dân tộc âm nhạc
                  học Mỹ, Hội Nghiên cứu âm nhạc truyền thống Nhật Bản, Hội
                  Nghiên cứu âm nhạc Ấn Độ.
                </p>
              </div>
              <div className="col-lg-6 col-md-12 m-b30">
                <div className="dz-media">
                  <img
                    src="https://danviet.mediacdn.vn/2020/7/24/tran-van-khe-7-15955687787471226804094.jpg"
                    alt=""
                    className="app-1"
                  />
                </div>
              </div>
            </div>
            <div className="row align-items-center project-bx left">
              <div className="col-lg-6 col-md-12 m-b30">
                <div className="dz-media">
                  <div>
                    <img
                      src="https://danviet.mediacdn.vn/2020/7/24/tran-van-khe-4-15955699339621187529770.jpg"
                      alt=""
                      className="app-1"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 m-b30">
                <h3 className="m-b20">Khen thưởng </h3>
                <div className="m-b0">
                  <p>
                    ● <strong>Chuông Mỹ Bội Tinh hạng I</strong> – Cộng Hòa Việt
                    Nam (1974)
                    <br />● <strong>Văn Hóa Bội Tinh hạng I</strong> – Cộng Hòa
                    Việt Nam (1974)
                    <br />● <strong>
                      Giải thưởng UNESCO – CIM về âm nhạc
                    </strong>{" "}
                    (1981)
                    <br />● <strong>
                      Huy chương Công dân ưu tú Tp.HCM
                    </strong>{" "}
                    (1981)
                    <br />● <strong>Fulbright Scholarship</strong> (1988) Bộ
                    ngoại giao Mỹ sắp đặt cho dạy học trong một năm tại Hololulu
                    về âm nhạc Dân tộc các nước truyền thống châu Á, đặc biệt âm
                    nhạc Dân tộc Việt Nam.
                    <br />● <strong>Officier des Arts et des Lettres</strong> –
                    Bộ VHTT Pháp (1991)
                    <br />● <strong>Huy chương Vì Văn hoá quần chúng</strong> –
                    Bộ VHTT Việt Nam (1991)
                    <br />●{" "}
                    <strong>Viện sĩ Thông tấn Hàn Lâm Viện Châu Âu</strong> Khoa
                    học Văn chương Nghệ thuật (1993)
                    <br />●{" "}
                    <strong>
                      Koizumi Fumio International Prize for Ethnomusicology
                    </strong>{" "}
                    (1994) Nhựt Bổn
                    <br />● <strong>Huân chương Lao động hạng Nhất</strong> –
                    Chủ tịch nước CHXHCNVN (1999)
                    <br />●{" "}
                    <strong>
                      Chevalier de l’Ordre des Palmes Academiques
                    </strong>{" "}
                    – Bộ Giáo dục Pháp (1999)
                    <br />● <strong>
                      Giải thưởng Vinh danh nước Việt
                    </strong>{" "}
                    (2003)
                    <br />● <strong>Giải thưởng Đào Tấn</strong> (2005)
                    <br />●{" "}
                    <strong>
                      Giải thưởng Quốc tế do Thành phố San Francisco (Mỹ)
                    </strong>{" "}
                    về “Đã cống hiến trọn đời cho việc nghiên cứu âm nhạc truyền
                    thống Việt Nam”.
                    <br />● <strong>Giải thưởng Phan Châu Trinh</strong> (2011)
                    <br />● <strong>Huy hiệu Thành phố Hồ Chí Minh</strong>{" "}
                    (2013)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="testimonial-wrapper1 content-inner-2"
          style={{ marginBottom: "32px" }}
        >
          <div className="section-head text-center">
            <h2 className="title">
              Những đóng góp chủ yếu cho Việt Nam và thế giới
            </h2>
            <p>
              Đã có trên 200 bài viết về m nhạc Việt Nam và âm nhạc Châu Á đã
              được đăng trong các tạp chí chuyên môn của nhiều nước, đặc biệt
              những bài viết cho Courrier de l’UNESCO được dịch ra 14 ngôn ngữ
              khác nhau
            </p>
          </div>
          <TestimonialSlider />
        </section>
        {/* <div className="call-action style-1 content-inner-1">
          <div className="container">
            <UpdateBlog />
          </div>
        </div> */}
      </div>
    </>
  );
};

export default ProjectStory;

import React from "react";
import { Link } from "react-router-dom";

import Pagebanner from "../layouts/PageBanner";
import CategoriesIcon from "../components/CategoriesIcon";

//images
import bg from "../assets/images/gstvk/activityBanner.jpg";
import shape2 from "../assets/images/pattern/shape2.png";
import shape3 from "../assets/images/pattern/shape3.png";
import shape5 from "../assets/images/pattern/shape5.png";
import shape1 from "../assets/images/pattern/shape1.png";
import shape6 from "../assets/images/pattern/shape6.png";
import large1 from "../assets/images/project/large/pic1.jpg";
import large2 from "../assets/images/project/large/pic2.jpg";
import large3 from "../assets/images/project/large/pic3.jpg";
import sign from "../assets/images/about/signature.png";
import danghoanhloan from "../assets/images/gstvk/danghoanhloan.jpg";
import buitronghien from "../assets/images/gstvk/buitronghien.jpg";
import cohuyhung from "../assets/images/gstvk/cohuyhung.jpg";
import nguyenvandoi from "../assets/images/gstvk/nguyenvandoi.jpg";
import phannhutdung from "../assets/images/gstvk/phannhutdung.jpg";
import nguyenthihaiphuong from "../assets/images/gstvk/nguyenthihaiphuong.jpg";

import OurMission from "../components/OurMission";
import UpdateBlog from "../components/Home/UpdateBlog";
import TVKScholarship from "../components/Activity/TVKScholarship";

const Activity = () => {
  function isScrolledIntoView(elem) {
    const spliBox = document.querySelectorAll(elem);
    spliBox.forEach(myFunction);
    function myFunction(item, index) {
      //   console.log("splitbox", item);
      const docViewTop = window.scrollY;
      const docViewBottom = docViewTop + window.innerHeight;
      let elemTop = item.getBoundingClientRect().top + docViewTop;
      const elemBottom = elemTop + item.offsetHeight;
      if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
        item.classList.add("split-active");
      }
    }
  }
  window.addEventListener("scroll", () => {
    isScrolledIntoView(".split-box");
  });
  return (
    <>
      <div className="page-content bg-white">
        <div
          className="dz-bnr-inr dz-bnr-inr-sm style-1 text-center"
          style={{ backgroundImage: "url(" + bg + ")" }}
        >
          <div className="container">
            <div className="dz-bnr-inr-entry">
              {/* <h1>HỌC BỔNG GIẢI THƯỞNG TRẦN VĂN KHÊ 2023</h1> */}
              {/* <nav aria-label="breadcrumb" className="breadcrumb-row">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/"}>{maintitle}</Link></li>
                                <li className="breadcrumb-item active">{pagetitle}</li>
                            </ul>
                        </nav> */}
            </div>
          </div>
        </div>
        <section
          className="content-inner bg-light section-pattren1"
          style={{ paddingTop: "50px", paddingBottom: "20px" }}
        >
          <div className="container">
            <div className="section-head text-center">
              <h2 className="title">
                TIỂU SỬ CÁC CÁ NHÂN NHẬN GIẢI THƯỞNG VÀ HỌC BỔNG TRẦN VĂN KHÊ
              </h2>
            </div>
          </div>
          {/* <img src={shape1} className="shape-1 move-1" alt="shape" /> */}
          <img src={shape2} className="shape-2 move-2" alt="shape" />
          <img src={shape3} className="shape-3 move-1" alt="shape" />
          <img src={shape5} className="shape-4 rotating" alt="shape" />
          {/* <img src={shape6} className="shape-5 rotating" alt="shape" /> */}
          <img src={shape6} className="shape-6 rotating" alt="shape" />
        </section>
        <section className="content-inner">
          <div className="container">
            <div className="section-head text-center">
              <h5 className="sub-title">VINH DANH</h5>
              <h2 className="title">Giải thưởng Trần Văn Khê 2023</h2>
            </div>
          </div>
        </section>

        <section className="content-inner-2" style={{ paddingTop: 0 }}>
          <div className="container">
            <div className="row align-items-center project-bx right">
              <div className="col-lg-8 col-md-12 m-b30">
                <div className="dz-content">
                  <h2 className="title m-b15">
                    Nhà giáo ưu tú - Nhạc sĩ Nguyễn Văn Đời
                    <br />
                    (1937)
                  </h2>
                  <h4>Tiểu sử</h4>
                  <p className="m-b0">
                    Gần 70 năm sống với nghề âm nhạc dân tộc, đã đào tạo nhiều
                    lứa học trò như nghệ sĩ Kim Hiền, nghệ sĩ Kim Uyên (Lê Thị
                    Kim), nghệ sĩ Nguyễn Thị Diệu (con nhạc sĩ Nguyễn Hữu Ba);
                    nghệ sĩ Vũ Kim Yến, TS-NSƯT Nguyễn Thị Hải Phượng…
                    <br />
                    ● Cựu trưởng ngành Quốc nhạc trường Quốc Gia Âm nhạc và kịch
                    nghệ Sài Gòn.
                    <br />
                    ● Nguyên trưởng khoa Âm nhạc dân tộc Nhạc viện thành phố Hồ
                    Chí Minh.
                    <br />
                    ● Năm 1956, học khóa đầu tiên ngành Quốc Nhạc của trường
                    Quốc Gia Âm nhạc Sài Gòn.
                    <br />
                    ● Năm 1972, được thưởng Văn hóa Giáo dục Bội tinh đệ nhị
                    hạng (chế độ trước).
                    <br />
                    ● Năm 1994, được phong tặng danh hiệu Nhà giáo Ưu tú.
                    <br />
                    ● Năm 1994, trình bày tham luận trong hội thảo "Giữ gìn bản
                    sắc trong đào tạo" tổ chức tại Huế, cảnh báo việc đào tạo âm
                    nhạc dân tộc đã có dấu hiệu chệch hướng.
                    <br />● Năm 1998, đề nghị đổi tên Khoa Nhạc cụ cổ truyền
                    thành Khoa Âm nhạc Dân tộc, ngoài việc đào tạo biểu diễn
                    nhạc khí, khoa còn có thể dạy tất cả kiến thức âm nhạc dân
                    tộc như hệ thống lý thuyết Ký xướng âm dân tộc, nhạc sử Việt
                    Nam...
                  </p>
                  <h4 style={{ marginTop: "12px" }}>Sáng tác</h4>
                  <p className="m-b0">
                    Nhiều tác phẩm hòa tấu nhạc cụ dân tộc cho dàn nhạc giao
                    hưởng như: Tam tấu đàn tranh viết theo hình thức mới; Độc
                    tấu đàn tranh 22 dây; Hòa tấu đàn tranh; Bộ gõ dân tộc…
                    <br />
                    ● Một concerto cho đàn tranh và bộ gõ dân tộc.
                    <br />
                    ● Một sonate cho 3 tranh (Tam tấu tranh viết theo hình thức
                    sonate).
                    <br />
                    ● Gió Xuân, độc tấu tranh, viết theo hình thức sonate.
                    <br />
                    ● Biến tấu điệu con sáo sang sông.
                    <br />● Ký âm đa số các bài bản cổ nhạc miền Nam đưa vào
                    chương trình giảng dạy .
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 m-b30">
                <div className="dz-media ">
                  <div>
                    <img src={nguyenvandoi} alt="" className="app-1" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-inner bg-light section-pattren1">
          <div className="container">
            <div className="row align-items-center project-bx left m-b30 sm-mb0">
              <div className="col-lg-4 col-md-12">
                <div className="dz-media ">
                  <div>
                    <img src={danghoanhloan} alt="" className="app-1" />
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="dz-content">
                  <h2 className="title m-b15">
                    Nhà nghiên cứu - Nhạc sĩ Đặng Hoành Loan
                    <br />
                    (1943)
                  </h2>
                  <h4>Tiểu sử</h4>
                  <p className="m-b0">
                    ● Từ 1959-1963, học và tốt nghiệp Khoa Nhạc cụ dân tộc
                    Trường Âm nhạc Việt Nam (nay là Học viện Âm nhạc Quốc gia
                    Việt Nam).
                    <br />
                    ● Từ năm 1964-1975, chỉ huy Dàn nhạc Đoàn Ca Kịch Liên khu
                    V.
                    <br />
                    ● Từ năm 1976, cán bộ nghiên cứu, sưu tầm Viện Âm nhạc.
                    <br />
                    ● Năm 1996, giữ chức Phó Viện trưởng Viện Âm nhạc kiêm
                    Trưởng ban biên tập tạp chí cho đến nay (Tạp chí Thông báo
                    khoa học nay đổi tên thành Tạp chí Nghiên cứu Âm nhạc-Viện
                    Âm nhạc).
                    <br />● Hiện giữ chức Chi hội trưởng, chi hội Hội Văn nghệ
                    dân gian Việt Nam Viện Âm nhạc.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="row align-items-center project-bx left m-b30 sm-mt0"
              style={{ marginTop: "60px" }}
            >
              <div className="col-lg-8 col-md-12">
                <div className="dz-content">
                  <h4>Sách, tài liệu, đề tài đã biên soạn</h4>
                  <p className="m-b0">
                    Phụ trách nghiên cứu điền dã xây dựng 5 hồ sơ Di sản văn hóa
                    Quốc gia trình UNESCO ghi vào danh sách Di sản văn hóa phi
                    vật thể và truyền khẩu nhân loại: Ca trù Việt Nam, Đờn ca
                    tài tử Nam bộ Việt Nam, Hát Xoan Phú Thọ Việt Nam, Bài chòi
                    Trung bộ Việt Nam, Hát Then Tày, Nùng, Thái Việt Nam.
                    <br />
                    ● Đã viết 45 bài nghiên cứu về âm nhạc cổ truyền người Việt
                    và âm nhạc dân gian các dân tộc thiểu số Việt Nam đăng trên
                    các tạp chí Nghiên cứu Âm nhạc, tạp chí Di Sản Văn hóa, tạp
                    chí Văn hóa Nghệ thuật và tạp chí Heritage.
                    <br />
                    ● Tìm hiểu cấu trúc âm nhạc trong lớp Tuồng Võ Tam Tư (tạp
                    chí Văn hóa Nghệ thuật, 1990).
                    <br />
                    ● Một phác thảo về âm nhạc dân gian và cổ truyền Quảng Trị
                    (sách Âm nhạc cổ truyền Quảng Trị, Viện Nghiên cứu Âm nhạc
                    và Sở Văn hóa Thông tin Quảng Trị, 1997).
                    <br />
                    ● Phát triển âm nhạc truyền thống – mục tiêu, kết quả và
                    những tồn tại (sách kỷ yếu Hội thảo khoa học Phát triển âm
                    nhạc truyền thống – ý nghĩa văn hóa và thành tựu nghệ thuật,
                    Bộ Văn hóa Thông tin, 2004).
                    <br />● Ca trù – những điều trông thấy (sách Đặc khảo Ca trù
                    Việt Nam, Viện Âm nhạc, 2006).
                    <br />
                    ● Cồng chiêng Tây Nguyên – không mà có (tạp chí Di sản văn
                    hóa, Bộ Văn hóa Thông tin, 2007).
                    <br />● Bộ sách Hát then các dân tộc Tày, Nùng, Thái VN (3
                    cuốn, NXB Văn hóa Dân tộc) giải B giải thưởng Sách quốc gia.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-12"
                style={{ alignSelf: "start" }}
              >
                <div className="dz-content">
                  <h4>Sáng tác</h4>
                  <p className="m-b0">
                    ● Huyền sử chiêng đồng (poème ballet, 1990).
                    <br />
                    ● Nghe kể H’Ri (nhạc múa).
                    <br />
                    Suối đàn T’rưng hát (hòa tấu 20 đàn T’rưng).
                    <br />
                    ● Sáng tác âm nhạc cho 5 vở kịch hát mới: Quạ thần và pho
                    tượng đá, Trầu Cau, Chốn quan trường, Thiên kim tình hận
                    Phạm Công - Cúc Hoa.
                    <br />● 2 album âm nhạc: Chuyện tình núi và sông và Dự cảm
                    (Nxb. Âm nhạc, 2007).
                  </p>
                  <h4 style={{ marginTop: "12px" }}>Khen thưởng</h4>
                  <p className="m-b0">
                    ● Huân chương Kháng chiến chống Mỹ cứu nước hạng Ba
                    <br />● Bằng khen Chính phủ và Huy chương 25 năm ngành Văn
                    hóa
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-inner-2">
          <div className="container">
            <div className="row align-items-center project-bx left m-b30 sm-mb0">
              <div className="col-lg-4 col-md-12">
                <div className="dz-media ">
                  <div>
                    <img src={phannhutdung} alt="" className="app-1" />
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="dz-content">
                  <h2 className="title m-b15">
                    Thạc sĩ - Nghệ sĩ Phan Nhứt Dũng
                    <br />
                    (1963)
                  </h2>
                  <h4>Tiểu sử</h4>
                  <p className="m-b0">
                    Xuất thân từ gia đình 4 đời có truyền thống nhạc Lễ Nam Bộ ở
                    Cần Đước, Long An, đến nay đã có 47 năm liên tục hoạt động
                    nghệ thuật âm nhạc truyền thống dân tộc.
                    <br />
                    ● Từ năm 13 tuổi bắt đầu học nhạc dân tộc từ gia đình do
                    cha, chú, dượng chỉ dạy.
                    <br />
                    ● Năm 1979 học trường Nghệ thuật sân khấu II.
                    <br />
                    ● Năm 1984, chính thức giảng dạy tại trường Nghệ thuật sân
                    khấu II.
                    <br />
                    ● Năm 2018, thạc sĩ ngành quản lý văn hóa.
                    <br />
                    ● Hiện nay là Giảng viên Khoa kịch hát DT, trường đại học
                    sân khấu điện ảnh TPHCM.
                    <br />
                    ● Từ năm 2003 đến nay là Chủ nhiệm CLB Tài tử-Cải lương tại
                    Cung văn hóa lao động TPHCM.
                    <br />● Từ năm 1994 đến 2013 tổ chức lưu diễn tại các nước
                    Pháp, Đan Mạch, Đức, Thụy Sĩ, Italia, Campuchia, …
                  </p>
                </div>
              </div>
            </div>
            <div
              className="row align-items-center project-bx left m-b30 sm-mt0"
              style={{ marginTop: "60px" }}
            >
              <div
                className="col-lg-6 col-md-12"
                style={{ alignSelf: "start" }}
              >
                <div className="dz-content">
                  <h4>Sách, tài liệu, đề tài đã biên soạn</h4>
                  <p className="m-b0">
                    ● Năm 2021, sáng kiến đưa âm nhạc Tài tử-Cải lương vào
                    trường học.
                    <br />
                    ● Năm 2014, Sáng kiến đưa âm nhạc Tài tử-Cải lương vào các
                    khu chế xuất.
                    <br />
                    ● Năm 1999, đề tài NCKH Tham gia, dàn dựng, bảo tồn và phát
                    huy giá trị di sản văn hóa có hiệu quả trong việc đối nội và
                    đối ngoại.
                    <br />
                    ● Năm 2017, đề tài NCKH “Nhạc lễ và đờn ca tài tử-di sản đặc
                    sắc trong âm nhạc cổ truyền Nam bộ”; “Sự tương tác của dàn
                    nhạc lễ và đờn ca tài tử Nam bộ”.
                    <br />
                    ● Năm 2019, viết đề tài “Nhạc lễ ở thành phố Hồ Chí Minh –
                    thực trạng và giải pháp”.
                    <br />
                    ● Năm 2021, viết đề tài “Một số kiểu dây trong âm nhạc tài
                    tử-cải lương Nam bộ”.
                    <br />
                    ● Năm 2002, biên soạn và bổ sung chương trình dạy nhạc cải
                    lương Trung cấp 4 năm.
                    <br />
                    ● Năm 2009, biên soạn và rút ngắn chương trình dạy nhạc cải
                    lương Trung cấp 2 năm.
                    <br />● Năm 2022, tham gia biên soạn tổng tập văn hóa dân
                    gian Nam bộ, đề tài Nhạc lễ dân gian Nam bộ.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12"
                style={{ alignSelf: "start" }}
              >
                <div className="dz-content">
                  <h4>Khen thưởng - Giải thưởng</h4>
                  <p className="m-b0">
                    ● Năm 2006, Kỷ niệm chương đã có công lao đối với sự nghiệp
                    giáo dục của đất nước, Bộ Giáo dục và đào tạo.
                    <br />
                    ● Năm 2014, bằng khen có thành tích xuất sắc trong việc bảo
                    tồn và phát huy giá trị di sản văn hóa phi vật thể đại diện
                    của nhân loại, Chủ tịch UBND thành phố Hồ Chí Minh.
                    <br />
                    ● Năm 2020 đạt giải 3 bản A Độc tấu Cải lương, Bộ trưởng Văn
                    hóa Thể thao và du lịch.
                    <br />
                    ● Năm 2020, bằng khen đã có thành tích vào quá trình hình
                    thành và phát triển đơn vị, nhân kỷ niệm 45 năm thành lập
                    Cung văn hóa Lao động TPHCM.
                    <br />● Năm 2022, giấy khen đã tích cực tham gia và đạt
                    thành tích cao tại liên hoan Đờn ca tài tử Quốc gia lần III
                    tại Cần Thơ, Sở Văn hóa Thể thao và du lịch.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-inner bg-light section-pattren1">
          <div className="container">
            <div className="row align-items-center project-bx right">
              <div className="col-lg-8 col-md-12 m-b30">
                <div className="dz-content">
                  <h2 className="title m-b15">
                    Nhà nghiên cứu Bùi Trọng Hiền
                    <br />
                    (1966)
                  </h2>
                  <h4>Tiểu sử</h4>
                  <p className="m-b0">
                    ● Từ năm 1990-1996: giảng viên Khoa Lý luận - Sáng tác- Chỉ
                    huy và là thành viên tổ bộ môn nghiên cứu lý thuyết âm nhạc
                    dân gian tại Nhạc viện Hà Nội.
                    <br />● Từ năm 1996 đến nay: Viện Văn hóa nghệ thuật quốc
                    gia Việt Nam.
                  </p>
                  <h4 style={{ marginTop: "12px" }}>
                    Truyền dạy âm nhạc dân tộc
                  </h4>
                  <p className="m-b0">
                    ● Năm 2017 và 2020 mở lớp hiệu chỉnh khuôn thước, âm luật
                    nhạc Ả đào cho các CLB ca trù Phú Thị (Hà Nội) và CLB ca trù
                    Hải Phòng.
                    <br />
                    ●Năm 2022 mở lớp truyền dạy cồng chiêng Tây Nguyên cho các
                    diễn viên Nhà hát Đó- Vega City Nha Trang.
                    <br />● Năm 2023, mở lớp tập huấn chỉnh âm cồng chiêng cho
                    đồng bào thiểu số tỉnh Kon Tum.( thang âm tộc người nào trả
                    về tộc người đó, kết hợp giữa định tính và định lượng).
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 m-b30">
                <div className="dz-media ">
                  <div>
                    <img src={buitronghien} alt="" className="app-1" />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "40px" }} className="sm-mt0 container">
              <h4>Sách, tài liệu, đề tài đã biên soạn</h4>
              <div className="row">
                <p className="col-lg-6 col-md-12 sm-mb0">
                  ● Năm 1999, Tư liệu âm nhạc cung đình Việt Nam (viết chung với
                  GS.TSKH Tô Ngọc Thanh), NXB Âm nhạc, Viện Âm nhạc.
                  <br />
                  ● Năm 2001, Lễ cưới người Dao Tuyển (Đồng tác giả), XNB Văn
                  hóa dân tộc.
                  <br />
                  ● Năm 2006, Đặc khảo ca trù Việt Nam (Đồng tác giả).
                  <br />
                  ● Năm 2021, Âm nhạc cồng chiêng Tây Nguyên, XNB Văn hóa dân
                  tộc, 2021.
                  <br />
                  ● Năm 1995, Về một vài thuật ngữ trong nhạc tài tử - cải
                  lương, Tạp chí Nghiên cứu Văn hóa Nghệ thuật, số 3, 1995.
                  <br />
                  ● Phương pháp xác định sơ đồ giai điệu những thể loại dân ca
                  hát ngâm thơ lục bát, Tạp chí Văn hóa Nghệ thuật, số 3, 1996.
                  <br />
                  ● Từ hai đặc trưng của âm nhạc dân gian đến luận, suy và ngẫm,
                  Tạp chí Văn hóa Nghệ thuật, số 11, 1996.
                  <br />● Vấn đề cung, giọng, điệu, hơi từ thực tiễn đến lý
                  thuyết, Tạp chí Văn hóa Nghệ thuật, số 6, 1997.
                  <br />● Suy nghĩ lại về vấn đề lòng bản và cách ký âm trong âm
                  nhạc cổ truyền, Tạp chí Văn hóa Nghệ thuật, số 12, 1997.
                </p>
                <p className="col-lg-6 col-md-12">
                  ● Hát trống quân ở Dạ Trạch, Tạp chí Văn hóa Nghệ thuật, số
                  10, 1999.
                  <br />
                  ● Về thể loại dân ca Vươn Bảo Xao của người Giáy, Tạp chí Văn
                  hóa Nghệ thuật, số 7-8, 2000.
                  <br />
                  ● Nguyên tắc diễn tấu cơ bản của nghệ thuật cồng chiêng Tây
                  Nguyên, Tạp chí Văn hóa Nghệ thuật, số 3, 2005.
                  <br />
                  ● Nghệ thuật cồng chiêng Êđê- vài đặc điểm nổi bật, Tạp chí
                  Văn hóa Nghệ thuật, số 10, 2005.
                  <br />
                  ● Nghệ thuật hát Văn và tín ngưỡng Tứ phủ, Tạp chí Văn hóa
                  Nghệ thuật, số 2, 2007.
                  <br />
                  ● Thang âm Cồng chiêng Tây Nguyên (Thông báo khoa học, số
                  31-32, Viện Âm nhạc)
                  <br />
                  ● Nhận diện Bài chòi Quãng Ngãi, Tạp chí Văn hóa nghệ thuật
                  các số 344, 346, 350, 351, 354 năm 2013.
                  <br />● Và nhiều chuyên luận khác về Ả đào cũng như văn hóa
                  tín ngưỡng dân gian.
                </p>
              </div>
            </div>
          </div>
          <img src={shape1} className="shape-1 move-1" alt="shape" />
          <img src={shape2} className="shape-2 move-2" alt="shape" />
          <img src={shape3} className="shape-3 move-1" alt="shape" />
          <img src={shape5} className="shape-4 rotating" alt="shape" />
          <img src={shape6} className="shape-5 rotating" alt="shape" />
          <img src={shape6} className="shape-6 rotating" alt="shape" />
        </section>

        <section className="content-inner-2">
          <div className="container">
            <div className="row align-items-center project-bx right">
              <div className="col-lg-7 col-md-12 m-b30">
                <div className="dz-content">
                  <h2 className="title m-b15">
                    Tiến sĩ - Nghệ sĩ ưu tú Nguyễn Thị Hải Phượng
                    <br />
                    (1969)
                  </h2>
                  <h4>Tiểu sử</h4>
                  <p className="m-b0">
                    ● Từ năm 1976, học trung cấp môn đàn Tranh, Nhạc viện
                    TP.HCM.
                    <br />
                    ● Năm 1992, giải Nhất cuộc thi tài năng trẻ đàn Tranh.
                    <br />
                    ● Từ năm 1999 đến nay, giảng viên môn đàn Tranh tại Nhạc
                    viện TPHCM.
                    <br />
                    ● Từ tháng 3/2021, Trưởng khoa Âm nhạc truyền thống tại Nhạc
                    viện TPHCM.
                    <br />
                    ● Năm 2004, tiến sĩ chuyên ngành Văn hóa học.
                    <br />
                    ● Hội viên hội Âm nhạc TPHCM.
                    <br />● Hội viên hội Nhạc sĩ Việt Nam.
                  </p>
                  <h4 style={{ marginTop: "12px" }}>
                    Sách, tài liệu, đề tài đã biên soạn
                  </h4>
                  <p className="m-b0">
                    Tác giả sách “Bóng rỗi và Chặp Địa nàng trong tín ngưỡng thờ
                    Mẫu của người Việt Nam Bộ”, 2017, NXB Mỹ Thuật, Hà Nội.
                    <br />
                    Bài viết “So sánh Đàn Tranh Việt Nam và các loại đàn cùng
                    họ”, Trường ĐH KHXN&NV TPHCM, 2014.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 m-b30">
                <div className="dz-media ">
                  <div>
                    <img src={nguyenthihaiphuong} alt="" className="app-1" />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "40px" }}>
              <div style={{ display: "flex" }} className="row">
                <div className="col-lg-6 col-md-12">
                  <h4>Tổ chức và trình diễn âm nhạc dân tộc</h4>
                  <p>
                    ● Năm 1993, ghi âm đĩa nhạc “Le Đan Tranh, musiqu é d’hier
                    et Aujourd’hui” cùng GSTS Trần Văn Khê hãng dĩa Occora, báo
                    Le Monde Pháp trao danh hiệu “CHOC” cho dĩa nhạc hay nhất
                    trong năm.
                    <br />
                    ● Từ năm 1992, đã lưu diễn, trình diễn âm nhạc dân tộc nhiều
                    nước như Hàn Quốc, Thái Lan, Pháp, Đức, Nhật Bản, Italia,
                    Trung Quốc, …
                    <br />● Tổ chức các chương trình như : tiết mục dự thi Liên
                    hoan độc tấu và Hòa tấu nhạc cụ dân tộc toàn quốc năm 2020;
                    chương trình Âm sắc Việt cùng HTV năm 2020; chương trình Sắc
                    Màu tại Nhạc Viện TPHCM năm 2022; chương trình thi Liên hoan
                    Độc tấu và Hòa tấu nhạc cụ dân tộc toàn quốc năm 2023
                  </p>
                </div>
                <div className="col-lg-6 col-md-12">
                  <h4>Khen thưởng - Giải thưởng</h4>
                  <p>
                    ● Giải Nhì tác phẩm viết cho đàn Tranh “Lời ru dòng sông” do
                    Hội Âm nhạc TPHCM trao tặng tháng 1/2023.
                    <br />
                    ● Năm 2017, đạt danh hiệu Nghệ sĩ ưu tú.
                    <br />
                    ● Năm 2019, nhận Kỷ niệm chương vì sự nghiệp giáo dục, Bộ
                    Văn hóa Thể thao và du lịch.
                    <br />
                    ● Năm 1993, 2000, 2013, 2014 bằng khen của Bộ trưởng Văn hóa
                    Thể thao và du lịch.
                    <br />● Năm 2011, 2013, Bằng khen của Ủy ban nhân dân TP. Hồ
                    Chí Minh.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-inner bg-light section-pattren1">
          <div className="container">
            <div className="row align-items-center project-bx left m-b30 sm-mb0">
              <div className="col-lg-5 col-md-12">
                <div className="dz-media ">
                  <div>
                    <img src={cohuyhung} alt="" className="app-1" />
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="dz-content">
                  <h2 className="title m-b15">
                    Tiến sĩ - Nghệ sĩ ưu tú Cồ Huy Hùng
                    <br />
                    (1974)
                  </h2>
                  <h4>Tiểu sử</h4>
                  <p className="m-b0">
                    ● Từ năm 1982, bắt đầu học khoa Âm nhạc truyền thống – Nhạc
                    viện Hà Nội, nhà giáo nhân dân Đặng Xuân Khải truyền dạy kỹ
                    năng chơi đàn Nguyệt, các làn điệu dân ca, các bài bản nhạc
                    Chèo, ca Huế, tài tử cải lương, hát Văn, …
                    <br />
                    ● Năm 2004-2008, học thạc sĩ chuyên ngành đàn Tứ (Ruan) tại
                    Học viện Âm nhạc Trung ương Bắc Kinh
                    <br />
                    ● Năm 2007, được phong danh hiệu Nghệ sĩ ưu tú
                    <br />
                    ● Năm 2013, Trưởng bộ môn đàn Nguyệt, Học viện Âm nhạc Quốc
                    gia Việt Nam
                    <br />
                    ● Năm 2017, hoàn thành luận án tiến sĩ “Đàn Nguyệt trong một
                    số phong cách nhạc cổ truyền người Việt”
                    <br />● Từ năm 2018, Trưởng khoa Âm nhạc truyền thống Việt
                    Nam
                  </p>
                </div>
              </div>
            </div>
            <div
              className="row align-items-center project-bx left m-b30 sm-mt0"
              style={{ marginTop: "60px" }}
            >
              <div
                className="col-lg-6 col-md-12"
                style={{ alignSelf: "start" }}
              >
                <div className="dz-content">
                  <h4>Sách, tài liệu, đề tài đã biên soạn</h4>
                  <p className="m-b0">
                    ● Năm 2004, Tuyển tập dân ca và ca khúc chuyển soạn cho đàn
                    Nguyệ.
                    <br />
                    ● Năm 2017, đàn Nguyệt trong phong cách hát Chầu Văn và nhạc
                    tài tử Nam bộ.
                    <br />
                    ● Năm 2007, Tuyển tập Chèo cổ cho Đàn Nguyệt.
                    <br />
                    ● Năm 2007, Tuyển tập Nhạc Huế cho đàn Nguyệt.
                    <br />● Năm 2019, biên soạn chương trình đào tạo tài năng hệ
                    trung cấp cho đàn Nguyệt.
                    <br />
                    ● Năm 2021, biên soạn chương trình đào tạo tài năng hệ đại
                    học cho đàn Nguyệt.
                    <br />● Năm 2022, biên soạn chương trình đào tạo ngành biểu
                    diễn đàn Nguyệt hệ trung cấp.
                    <br />● Năm 2011, bài viết “Đàn Nguyệt lung linh trong tâm
                    hồn những người yêu nhạc Việt”.
                    <br />● Năm 2016, bài viết “Thử đi tìm nguồn gốc của cây đàn
                    Nguyệt Việt Nam qua việc so sánh với cây đàn Nguyễn và đàn
                    Nguyệt của Trung Quốc”, tạp chí giáo dục Âm nhạc.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="dz-content">
                  <h4>Tổ chức và trình diễn âm nhạc dân tộc</h4>
                  <p>
                    Tham gia dàn dựng và biểu diễn cùng dàn nhạc dân tộc Việt
                    Nam các chương trình Cung đàn quê hương (2016); Ngày Âm nhạc
                    Việt Nam lần thứ IX (2018); Chung một niềm tin (2018), Cung
                    đàn thương nhớ (2022), …
                    <br />
                    Lưu diễn tại các nước Nga, Pháp, Argentina, Italia, Hàn
                    Quốc, Kazakhstan, Dubai, …
                  </p>
                  <h4>Khen thưởng - Giải thưởng</h4>
                  <p className="m-b0">
                    ● Năm 1985 Huy chương vàng, độc tấu đàn Nguyệt, Hội diễn Ca
                    múa nhạc dân tộc toàn quốc.
                    <br />
                    ● Năm 1992, Huy chương vàng, độc tấu đàn Nguyệt, Hội thi ca
                    múa nhạc dân tộc toàn quốc.
                    <br />
                    ● Năm 1999, Giải đặc biệt, Âm nhạc hay nhất cho đoàn nghệ
                    thuật Việt Nam tham gia festival Quốc tế tại Ialia.
                    <br />
                    ● Năm 2014, Huy chương bạc, Liên hoan độc tấu và hòa tấu
                    nhạc cụ dân tộc.
                    <br />
                    ● Năm 2017, Huy chương vàng, Liên hoan độc tấu và hòa tấu
                    nhạc cụ dân tộc.
                    <br />● Năm 2020, Giải Nhất bảng E độc tấu khối đào tạo dành
                    riêng cho Giảng viên, Cuộc thi độc tấu và hòa tấu nhạc cụ
                    dân tộc toàn quốc.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <img src={shape1} className="shape-1 move-1" alt="shape" />
          <img src={shape2} className="shape-2 move-2" alt="shape" />
          <img src={shape3} className="shape-3 move-1" alt="shape" />
          <img src={shape5} className="shape-4 rotating" alt="shape" />
          <img src={shape6} className="shape-5 rotating" alt="shape" />
          <img src={shape6} className="shape-6 rotating" alt="shape" />
        </section>
        <TVKScholarship />
        {/* <div className="call-action style-1 content-inner-1">
          <div className="container">
            <UpdateBlog />
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Activity;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

import test1 from "../../assets/images/testimonials/large/pic1.jpg";
import test2 from "../../assets/images/testimonials/large/pic2.jpg";
import test3 from "../../assets/images/testimonials/large/pic3.jpg";
import test4 from "../../assets/images/testimonials/large/pic1.jpg";
import shape1 from "../../assets/images/pattern/shape1.png";
import shape2 from "../../assets/images/pattern/shape2.png";
import shape3 from "../../assets/images/pattern/shape3.png";
import shape5 from "../../assets/images/pattern/shape5.png";
import shape6 from "../../assets/images/pattern/shape6.png";
import dobaouyen from "../../assets/images/gstvk/dobaouyen.jpg";
import nguyenducthien from "../../assets/images/gstvk/nguyenducthien.jpg";
import trinhnhatminh from "../../assets/images/gstvk/trinhnhatminh.jpg";
import phanduykhanh from "../../assets/images/gstvk/phanduykhanh.jpg";
import huynhtuelam from "../../assets/images/gstvk/huynhtuelam.jpg";
import vothihuonggiang from "../../assets/images/gstvk/vothihuonggiang.jpg";
import phambaotoan from "../../assets/images/gstvk/phambaotoan.jpg";
import nguyenhaiminh from "../../assets/images/gstvk/nguyenhaiminh.jpg";
import votranlannhi from "../../assets/images/gstvk/votranlannhi.jpg";

const TVKScholarship = () => {
  return (
    <section className="content-inner section-pattren1">
      <div className="container">
        <div className="section-head text-center">
          <h5 className="sub-title">VINH DANH</h5>
          <h2 className="title">Học bổng Trần Văn Khê 2023</h2>
        </div>
        <div className="row">
          <div className="col-lg-10 m-b50">
            <div className="testimonial-3 bg-light">
              <div className="testimonial-media" style={{ background: "none" }}>
                <img
                  src={dobaouyen}
                  alt=""
                  style={{ objectPosition: "top", opacity: 1 }}
                />
              </div>
              <div className="testimonial-content hide-shape">
                <h5 className="testimonial-title">
                  Đỗ Bảo Uyên
                  <br />
                  Học Viện Âm Nhạc Quốc Gia Việt Nam
                </h5>
                <p className="testimonial-text" style={{ marginBottom: 0 }}>
                  ● Sinh năm 2007.
                  <br />● Học ngành Tam Thập Lục từ 2018 - nay.
                  <br />● Giảng dạy, biểu diễn tại CLB Hát xẩm Tâm Việt.
                  <br />● Giải nhất hòa tấu dàn nhạc, cuộc thi Đàn, và hát dân
                  ca 2013.
                  <br />● Độc tấu trong chương trình hội nghị 2022 Thái Lan
                  2018.
                  <br />● Thành viên nhóm "Nắng Xuân" biểu diễn tại Solla Music
                  Festival Thanh Hóa 2023.
                  <br />● Tham gia biểu diễn giao lưu, biểu diễn độc tấu các
                  chương trình quảng bá Âm nhạc dân tộc.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-10 offset-lg-2 m-b50">
            <div className="testimonial-3 right bg-light">
              <div className="testimonial-content hide-shape">
                <h5 className="testimonial-title">
                  Nguyễn Đức Thiện
                  <br />
                  Học Viện Âm Nhạc Quốc Gia Việt Nam
                </h5>
                <p className="testimonial-text" style={{ marginBottom: 0 }}>
                  ● Sinh năm 2000.
                  <br />● Trung cấp Âm nhạc dân tộc từ 2017 - 2021.
                  <br />● Đại học Âm nhạc dân tộc từ 2021 - nay.
                  <br />● Giảng dạy, biểu diễn tại CLB Hát xẩm Tâm Việt.
                  <br />● 2 giải A, 1 giải C tại Liên hoan hát Xẩm toàn quốc năm
                  2022.
                  <br />● Tham gia Festival Âm nhạc dân tộc dành cho người khiếm
                  thị tại Thái Lan 2018.
                  <br />● Thu thanh CD "Hy vọng" nhạc cụ dân tộc cùng NS. Tôn
                  Thất Triêm và NS. Xuân Thanh.
                  <br />● Tham gia biểu diễn Âm nhạc dân tộc trong và ngoài
                  nước, gây quỹ cộng đồng.
                </p>
              </div>
              <div className="testimonial-media" style={{ background: "none" }}>
                <img
                  src={nguyenducthien}
                  alt=""
                  style={{ objectPosition: "top", opacity: 1 }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-10 m-b50">
            <div className="testimonial-3 bg-light">
              <div className="testimonial-media" style={{ background: "none" }}>
                <img
                  src={trinhnhatminh}
                  alt=""
                  style={{ objectPosition: "top", opacity: 1 }}
                />
              </div>
              <div className="testimonial-content hide-shape">
                <h5 className="testimonial-title">
                  Trịnh Nhật Minh
                  <br />
                  Học Viện Âm Nhạc Quốc Gia Việt Nam
                </h5>
                <p className="testimonial-text" style={{ marginBottom: 0 }}>
                  ● Sinh năm 2006.
                  <br />● Trung cấp Đàn bầu từ 2017 - nay.
                  <br />● Giải nhất độc tấu bảng F cuộc thi Độc tấu và hòa tấu
                  nhạc cụ dân tộc toàn quốc năm 2020.
                  <br />● Giải Ngôi sao hy vọng cuộc thi Độc tấu và hòa tấu nhạc
                  cụ dân tộc toàn quốc năm 2020.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-10 offset-lg-2 m-b50">
            <div className="testimonial-3 right bg-light">
              <div className="testimonial-content hide-shape">
                <h5 className="testimonial-title">
                  Phan Duy Khánh
                  <br />
                  Học Viện Âm Nhạc Huế
                </h5>
                <p className="testimonial-text" style={{ marginBottom: 0 }}>
                  ● Sinh năm 2001.
                  <br />● Học ngành Đàn Nguyệt từ 2018 - nay.
                  <br />● Giải Nhì độc tấu Đàn Nguyệt, hội thi tài năng trẻ học
                  sinh, sinh viên toàn quốc năm 2020.
                  <br />● Tham gia biểu diễn âm nhạc dân tộc ban nhạc "Thanh
                  Xuân" của trường.
                  <br />● Tham dự hội thi Độc tấu - Hòa tấu toàn quốc năm 2023.
                </p>
              </div>
              <div className="testimonial-media" style={{ background: "none" }}>
                <img
                  src={phanduykhanh}
                  alt=""
                  style={{ objectPosition: "top", opacity: 1 }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-10 m-b50">
            <div className="testimonial-3 bg-light">
              <div className="testimonial-media" style={{ background: "none" }}>
                <img
                  src={huynhtuelam}
                  alt=""
                  style={{ objectPosition: "top", opacity: 1 }}
                />
              </div>
              <div className="testimonial-content hide-shape">
                <h5 className="testimonial-title">
                  Huỳnh Tuệ Lâm
                  <br />
                  Học Viện Âm Nhạc Huế
                </h5>
                <p className="testimonial-text" style={{ marginBottom: 0 }}>
                  ● Sinh năm 2012.
                  <br />● Học trung cấp Đàn Tranh từ 2022.
                  <br />● Tham gia biểu diễn nghệ thuật đường phố Hai Bà Trưng,
                  phường Trường An.
                  <br />● Tham gia biểu diễn Âm nhạc cổ truyền.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-10 offset-lg-2 m-b50">
            <div
              className="testimonial-3 right bg-light"
              style={{ justifyContent: "space-between" }}
            >
              <div className="testimonial-content hide-shape">
                <h5 className="testimonial-title">
                  Võ Thị Hương Giang
                  <br />
                  Học Viện Âm Nhạc Huế
                </h5>
                <p className="testimonial-text" style={{ marginBottom: 0 }}>
                  ● Sinh năm 2002.
                  <br />● Học ngành Đàn Tranh từ 2018 - nay.
                  <br />● Tham gia cuộc thi hòa tấu nhạc cụ dân tộc toàn quốc
                  tại Nha Trang.
                  <br />● Tham gia biểu diễn âm nhạc dân tộc cộng đồng.
                </p>
              </div>
              <div className="testimonial-media" style={{ background: "none" }}>
                <img
                  src={vothihuonggiang}
                  alt=""
                  style={{ objectPosition: "top", opacity: 1 }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-10 m-b50">
            <div className="testimonial-3 bg-light">
              <div className="testimonial-media" style={{ background: "none" }}>
                <img
                  src={phambaotoan}
                  alt=""
                  style={{ objectPosition: "top", opacity: 1 }}
                />
              </div>
              <div className="testimonial-content hide-shape">
                <h5 className="testimonial-title">
                  Phạm Bảo Toàn
                  <br />
                  Nhạc viện Thành phố Hồ Chí Minh
                </h5>
                <p className="testimonial-text" style={{ marginBottom: 0 }}>
                  ● Sinh năm 2001.
                  <br />● Học ngành Sáo trúc từ 2019 - nay.
                  <br />● Tham gia biểu diễn chương trình Sắc màu.
                  <br />● Tham gia biểu diễn chương trình "Dòng thanh xuân".
                  <br />● Thành tích học tập xuất sắc.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-10 offset-lg-2 m-b50">
            <div
              className="testimonial-3 right bg-light"
              style={{ justifyContent: "space-between" }}
            >
              <div className="testimonial-content hide-shape">
                <h5 className="testimonial-title">
                  Nguyễn Hải Minh
                  <br />
                  Nhạc viện Thành phố Hồ Chí Minh
                </h5>
                <p className="testimonial-text" style={{ marginBottom: 0 }}>
                  ● Sinh năm 2004.
                  <br />● Học ngành Đàn Tranh từ 2017 - nay.
                  <br />● Giải Nhất cuộc thi "Em yêu đàn tranh lần I" CVHLĐ
                  TPHCM năm 2018.
                  <br />● Giải Nhì cuộc thi "Độc tấu - Hòa tấu Nhạc cụ dân tộc
                  Việt Nam" NV TPHCM năm 2018.
                  <br />● Giải Ba "Hội thi tài năng trẻ học sinh, sinh viên các
                  cơ sở đào tạo VHNT toàn quốc", 2020.
                  <br />● Giải Nhì độc tấu đàn tranh "Liên hoan Ca múa dân tộc -
                  Giai điệu Quê hương" lần XVIII, 2020.
                  <br />● Tham gia biểu diễn âm nhạc dân tộc nhiều chương trình.
                </p>
              </div>
              <div className="testimonial-media" style={{ background: "none" }}>
                <img
                  src={nguyenhaiminh}
                  alt=""
                  style={{ objectPosition: "top", opacity: 1 }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-10 m-b50">
            <div className="testimonial-3 bg-light">
              <div className="testimonial-media" style={{ background: "none" }}>
                <img
                  src={votranlannhi}
                  alt=""
                  style={{ objectPosition: "top", opacity: 1 }}
                />
              </div>
              <div className="testimonial-content hide-shape">
                <h5 className="testimonial-title">
                  Võ Trần Lan Nhi
                  <br />
                  Nhạc viện Thành phố Hồ Chí Minh
                </h5>
                <p className="testimonial-text" style={{ marginBottom: 0 }}>
                  ● Sinh năm 2006.
                  <br />● Học ngành Đàn Nguyệt từ 2017 - nay.
                  <br />● Huy chương Vàng Giải búp sen vàng, TTVH TPHCM, 2016.
                  <br />● Giải triển vọng Độc tấu đàn Nguyệt, Nhạc viện TPHCM,
                  2018.
                  <br />● Giải Ba "Tứ tấu dân tộc: Lưu thủy, hành vân Vọng cổ"
                  Bộ VHTT&DL, 2020.
                  <br />● Tham gia biểu diễn âm nhạc dân tộc nhiều chương trình.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src={shape1} className="shape-1 move-1" alt="shape" />
      <img src={shape2} className="shape-2 move-2" alt="shape" />
      <img src={shape3} className="shape-3 move-1" alt="shape" />
      <img src={shape5} className="shape-4 rotating" alt="shape" />
      <img src={shape6} className="shape-5 rotating" alt="shape" />
      <img src={shape6} className="shape-6 rotating" alt="shape" />
    </section>
  );
};

export default TVKScholarship;

import React, { useRef, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
//import "swiper/css/pagination";
import DonutChart2 from "../DonutChart2";

import SwiperCore, { Autoplay, Navigation, EffectFade } from "swiper";

import DonateModal from "../Modal/DonateModal";

// import slide1 from "../../assets/images/main-slider/slider1/slider-bg1.jpg";
// import slide2 from "../../assets/images/main-slider/slider1/slider-bg2.jpg";
// import slide3 from "../../assets/images/main-slider/slider1/slider-bg3.jpg";
import shape1 from "../../assets/images/main-slider/slider1/shape1.svg";
import line1 from "../../assets/images/main-slider/slider1/shape-line1.svg";
import shape2 from "../../assets/images/main-slider/slider1/shape2.svg";
import line2 from "../../assets/images/main-slider/slider1/shape-line2.svg";
// import pic1 from "../../assets/images/main-slider/slider1/pic1.jpg";
// import pic2 from "../../assets/images/main-slider/slider1/pic2.jpg";
// import pic3 from "../../assets/images/main-slider/slider1/pic3.jpg";
// import pic4 from "../../assets/images/main-slider/slider1/pic4.jpg";
// import pic5 from "../../assets/images/main-slider/slider1/pic5.jpg";
// import pic6 from "../../assets/images/main-slider/slider1/pic6.jpg";
// import pic7 from "../../assets/images/main-slider/slider1/pic7.jpg";
// import pic8 from "../../assets/images/main-slider/slider1/pic8.jpg";
// import pic9 from "../../assets/images/main-slider/slider1/pic9.jpg";
import gstvk1 from "../../assets/images/gstvk/gstvk1.png";
import gstvk2 from "../../assets/images/gstvk/gstvk2.jpg";
import gstvk3 from "../../assets/images/gstvk/gstvk3.jpg";
import gstvk4 from "../../assets/images/gstvk/gstvk4.jpg";
import gstvk5 from "../../assets/images/gstvk/gstvk5.jpg";
import gstvk6 from "../../assets/images/gstvk/gstvk6.jpg";
import gstvk7 from "../../assets/images/gstvk/gstvk7.webp";
import gstvk8 from "../../assets/images/gstvk/gstvk8.jpg";
import gstvk9 from "../../assets/images/gstvk/gstvk9.jpg";
import gstvk10 from "../../assets/images/gstvk/gstvk10.jpg";
import gstvk11 from "../../assets/images/gstvk/gstvk11.jpg";
import gstvk22 from "../../assets/images/gstvk/gstvk22.jpg";
import gstvk23 from "../../assets/images/gstvk/gstvk23.jpg";
import gstvk24 from "../../assets/images/gstvk/gstvk24.jpg";
import gstvk26 from "../../assets/images/gstvk/gstvk26.jpg";
import nhaccu from "../../assets/images/gstvk/nhaccu.jpg";

const dataBlog = [
  {
    title1: "Quỹ học bổng Trần Văn Khê",
    title2: "Quỹ xã hội hoạt động không vì mục tiêu lợi nhuận",
    baner: gstvk4,
    img2: gstvk6,
    img3: gstvk26,
    img4: gstvk2,
  },
  {
    title1: "Giải thưởng Trần Văn Khê",
    title2: "Tôn vinh tài năng âm nhạc truyền thống Việt Nam",
    baner: gstvk7,
    img2: gstvk8,
    img3: gstvk1,
    img4: gstvk24,
  },
  {
    title1: "TRẦN VĂN KHÊ FOUNDATION",
    title2: "Hiện thực hóa di nguyện của GS. Trần Văn Khê",
    baner: gstvk10,
    img2: gstvk3,
    img3: gstvk11,
    img4: gstvk22,
  },
  //   {
  //     title1: "HELPING HAND FOR CHILDREN",
  //     title2: "Give a Helping Hand for Children",
  //     baner: slide2,
  //     img2: pic4,
  //     img3: pic5,
  //     img4: pic6,
  //   },
  //   {
  //     title1: "CHILD THE OF EDUCATION",
  //     title2: "Give  a Child the of Education",
  //     baner: slide3,
  //     img2: pic7,
  //     img3: pic8,
  //     img4: pic9,
  //   },
];

const Mainslider2 = ({ onShowDonate }) => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  //const paginationRef = React.useRef(null)
  const modalRef = useRef(null);
  SwiperCore.use([Autoplay]);

  // const [timerDays, setTimerDays] = useState("00");
  // const [timerHours, setTimerHours] = useState("00");
  // const [timerMinutes, setTimerMinutes] = useState("00");
  // const [timerSeconds, setTimerSeconds] = useState("00");
  const [referModal, setReferModal] = useState(false);
  // let interval = useRef();

  // const startTimer = () => {
  //   /* Website Launch Date */
  //   var WebsiteLaunchDate = new Date();
  //   var monthNames = [
  //     "January",
  //     "February",
  //     "March",
  //     "April",
  //     "May",
  //     "June",
  //     "July",
  //     "August",
  //     "September",
  //     "October",
  //     "November",
  //     "December",
  //   ];
  //   WebsiteLaunchDate.setMonth(WebsiteLaunchDate.getMonth() + 1);
  //   WebsiteLaunchDate =
  //     WebsiteLaunchDate.getDate() +
  //     " " +
  //     monthNames[WebsiteLaunchDate.getMonth()] +
  //     " " +
  //     WebsiteLaunchDate.getFullYear();
  //   /* Website Launch Date END */

  //   //alert(WebsiteLaunchDate);

  //   // const countdownDate = new Date(`Dec 31, ${d.getFullYear()} 00:01:00`).getTime();
  //   const countdownDate = new Date(
  //     "Sun Jul 23 2023 08:30:00 GMT+0700"
  //   ).getTime();
  //   interval = setInterval(() => {
  //     const now = new Date().getTime();
  //     const distance = countdownDate - now;
  //     const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  //     const hours = Math.floor(
  //       (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //     );
  //     const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //     const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  //     if (distance < 0) {
  //       //stop our timer
  //       clearInterval(interval.current);
  //     } else {
  //       //update timer
  //       setTimerDays(days);
  //       setTimerHours(hours);
  //       setTimerMinutes(minutes);
  //       setTimerSeconds(seconds);
  //     }
  //   }, 1000);
  // };

  // //componentDidMount
  // useEffect(() => {
  //   startTimer();
  //   return () => {
  //     clearInterval(interval.current);
  //   };
  // });

  return (
    <>
      <Swiper
        className="main-slider"
        speed={2000}
        // parallax={true}
        slidesPerView={1}
        effect="fade"
        spaceBetween={0}
        loop={true}
        preventInteractionOnTransition={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        // navigation={true}
        // pagination={{ clickable: false }}
        // onSwiper={(swiper) => {
        //   setTimeout(() => {
        //     swiper.params.navigation.prevEl = navigationPrevRef.current;
        //     swiper.params.navigation.nextEl = navigationNextRef.current;
        //     swiper.navigation.destroy();
        //     swiper.navigation.init();
        //     swiper.navigation.update();
        //   }, 1000);
        // }}
        // pagination= {{
        //     el= {.swiper-pagination}
        //     clickable= {true}
        // }}
        modules={[Autoplay, Navigation, EffectFade]}
      >
        {dataBlog.map((d, i) => (
          <SwiperSlide key={i}>
            <div
              className="banner-inner"
              style={{ backgroundImage: "url(" + d.baner + ")" }}
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6 col-md-12 xl-mt32">
                    <div className="banner-content">
                      <div className="top-content tranvankhe">
                        <h5
                          className="sub-title text-secondary"
                          style={{ marginBottom: 0 }}
                        >
                          {d.title1}
                        </h5>
                        <h1 className="title" style={{ marginBottom: "16px" }}>
                          Lễ trao Giải thưởng và Học bổng Trần Văn Khê năm 2023
                        </h1>
                        <h5 style={{ color: "white" }}>{d.title2} </h5>
                        <div style={{ marginBottom: "12px", color: "#D7D7D7" }}>
                          {/* Quỹ xã hội hoạt động nhằm mục đích hỗ trợ và tạo điều
                          kiện cổ vũ, giúp đỡ các sinh viên chuyên ngành âm nhạc
                          dân tộc có thành tích cao trong học tập và các nhà
                          nghiên cứu, các nghệ sĩ có công trình, tác phẩm và
                          hoạt động nổi bật góp phần gìn giữ, phát huy các giá
                          trị âm nhạc dân tộc Việt Nam trên địa bàn thành phố Hồ
                          Chí Minh và cả nước. */}
                          {/* <i class="fa-regular fa-clock"></i> Thời gian:{" "}
                          <span style={{ fontWeight: 600, color: "white" }}>
                            8:30 sáng chủ nhật 23/07/2023
                          </span>
                          <br />
                          <i class="fa-solid fa-location-dot"></i> Địa điểm:{" "}
                          <span style={{ fontWeight: 600, color: "white" }}>
                            Nhà hát Thành Phố Hồ Chí Minh
                          </span>{" "}
                          (07 Công Trường Lam Sơn, Bến Nghé, Quận 1)
                          <br /> */}
                          <p style={{ marginBottom: "8px" }}>
                            <i class="fa-solid fa-heart"></i>{" "}
                            <span style={{ fontWeight: 600, color: "white" }}>
                              Trao giải thưởng và học bổng
                            </span>{" "}
                            để khuyến khích, cổ vũ, tôn vinh những người đang
                            theo đuổi sự nghiệp học tập, nghiên cứu, giảng dạy,
                            xuất bản, biểu diễn nhằm góp phần gìn giữ, bảo tồn
                            giá trị âm nhạc dân tộc.
                            <br />
                          </p>
                          <p style={{ marginBottom: "8px" }}>
                            <i class="fa-solid fa-award"></i>{" "}
                            <span style={{ fontWeight: 600, color: "white" }}>
                              Giải thưởng Trần Văn Khê năm 2023 vinh dự được
                              trao cho:
                            </span>
                            <br />
                            ● Nhà nghiên cứu - nhạc sĩ Đặng Hoành Loan
                            <br />
                            ● Nhà Nghiên cứu Bùi Trọng Hiền
                            <br />
                            ● Tiến sĩ – Nghệ sĩ ưu tú Cồ Huy Hùng
                            <br />
                            ● Nhà giáo ưu tú - nhạc sĩ Nguyễn Văn Đời
                            <br />● Thạc sĩ – Nghệ sĩ Phan Nhứt Dũng
                            <br />● Tiến sĩ - Nghệ sĩ ưu tú Nguyễn Thị Hải
                            Phượng
                          </p>
                          <p style={{ marginBottom: 0 }}>
                            <i class="fa-solid fa-music"></i>{" "}
                            <span style={{ fontWeight: 600, color: "white" }}>
                              Những cá nhân xuất sắc nhận Học bổng Trần Văn Khê
                              năm 2023:
                            </span>
                          </p>
                          <div style={{ display: "flex", alignItems: "start" }}>
                            <p
                              style={{ marginRight: "12px", marginBottom: "0" }}
                            >
                              ● Đỗ Bảo Uyên
                              <br />
                              ● Nguyễn Đức Thiện
                              <br />
                              ● Trịnh Nhật Minh
                              <br />
                              ● Phan Duy Khánh
                              <br />● Huỳnh Tuệ Lâm
                            </p>
                            <p style={{ marginBottom: 0 }}>
                              ● Võ Thị Hương Giang
                              <br />● Phạm Bảo Toàn
                              <br />● Nguyễn Hải Minh
                              <br />● Võ Trần Lan Nhi
                            </p>
                          </div>
                        </div>
                        <Modal
                          className="modal fade modal-wrapper"
                          id="modalRefer"
                          show={referModal}
                          onHide={setReferModal}
                          centered
                        >
                          <div className="modal-header">
                            <h5 className="modal-title">Thông tin liên hệ</h5>
                            <button
                              type="button"
                              className="btn-close"
                              onClick={() => setReferModal(false)}
                            >
                              <i className="flaticon-close"></i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <form className="dz-form contact-bx" method="POST">
                              <div className="form-group style-1 align-items-center">
                                <h4>Thư ký: Ngọc Hân</h4>
                                {/* <div className="input-group">
                                          <input name="dzName" required="" type="text" className="form-control" placeholder="Jakob Bothman" />
                                      </div> */}
                              </div>
                              <div className="form-group style-1 align-items-center">
                                <p style={{ marginBottom: 0 }}>
                                  Email: tranvankhefoundation@gmail.com
                                </p>
                                <p>Tel: 0782 78 78 28</p>
                                {/* <div className="input-group">
                                          <input name="dzEmail" required="" type="text" className="form-control" placeholder="marseloque@mail.com" />
                                      </div> */}
                              </div>
                              {/* <div className="form-group style-1 align-items-center">
                                      <label className="form-label">Tel: 0782 78 78 28</label>
                                      <div className="input-group">
                                          <input name="dzPhoneNumber" type="number" className="form-control" placeholder="Phone Number" />
                                      </div>
                                  </div> */}
                              <div className="form-group mb-0 text-center">
                                <a
                                  href={
                                    isMobile
                                      ? "tel:0782787828"
                                      : "https://chat.zalo.me/?phone=0782787828"
                                  }
                                  className="btn btn-primary"
                                >
                                  Liên hệ
                                </a>
                              </div>
                            </form>
                          </div>
                        </Modal>
                        {/* <h5 style={{ color: "white", marginBottom: "12px" }}>
                          Lễ trao học bổng Trần Văn Khê sẽ diễn ra sau:
                        </h5>
                        <div className="countdown-timer countdown-container">
                          <div className="clock">
                            <div className="clock-item clock-days countdown-time-value">
                              <div id="canvas-days" className="clock-canvas">
                                <DonutChart2
                                  value={timerDays}
                                  backgroundColor="rgba(255,255,255,1)"
                                  backgroundColor2="rgba(255, 255, 255,0.4)"
                                />
                              </div>
                              <div className="text">
                                <p className="val">{timerDays}</p>
                                <p
                                  className="type-days type-time"
                                  data-border-color="#fff"
                                >
                                  Ngày
                                </p>
                              </div>
                            </div>
                            <div className="clock-item clock-hours countdown-time-value">
                              <div id="canvas-hours" className="clock-canvas">
                                <DonutChart2
                                  value={timerHours}
                                  backgroundColor="rgba(255,255,255,1)"
                                  backgroundColor2="rgba(255, 255, 255,0.4)"
                                />
                              </div>
                              <div className="text">
                                <p className="val">{timerHours}</p>
                                <p
                                  className="type-hours type-time"
                                  data-border-color="#fff"
                                >
                                  Giờ
                                </p>
                              </div>
                            </div>
                            <div className="clock-item clock-minutes countdown-time-value">
                              <div id="canvas-minutes" className="clock-canvas">
                                <DonutChart2
                                  value={timerMinutes}
                                  backgroundColor="rgba(255,255,255,1)"
                                  backgroundColor2="rgba(255, 255, 255,0.4)"
                                />
                              </div>
                              <div className="text">
                                <p className="val">{timerMinutes}</p>
                                <p
                                  className="type-minutes type-time"
                                  data-border-color="#fff"
                                >
                                  Phút
                                </p>
                              </div>
                            </div>
                            <div className="clock-item clock-seconds countdown-time-value">
                              <div id="canvas-seconds" className="clock-canvas">
                                <DonutChart2
                                  value={timerSeconds}
                                  backgroundColor="rgba(255,255,255,1)"
                                  backgroundColor2="rgba(255, 255, 255,0.4)"
                                />
                              </div>
                              <div className="text">
                                <p className="val">{timerSeconds}</p>
                                <p
                                  className="type-seconds type-time"
                                  data-border-color="#fff"
                                >
                                  Giây
                                </p>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div
                          className="d-flex align-items-center"
                          style={{ marginTop: "12px" }}
                        >
                          {/* <Link
                            to={"#"}
                            className="btn btn-primary btnhover1"
                            //onClick={()=>onShowDonate(true)}
                            onClick={() => {
                              modalRef.current.handleModalOpen();
                            }}
                          >
                            <span>Quyên góp</span>
                            <i className="flaticon-heart text-secondary ms-3"></i>
                          </Link> */}
                          {/* <a
                            href={isMobile ? "tel:0782787828" : "https://chat.zalo.me/?phone=0782787828"}
                            className="btn btn-secondary btnhover2"
                          >
                            Đăng ký{" "}
                            <i className="flaticon-heart ms-3"></i>
                          </a> */}
                          <button
                            onClick={() => setReferModal(true)}
                            className="btn btn-secondary btnhover2"
                          >
                            Đăng ký <i className="flaticon-heart ms-3"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="banner-media row gx-4">
                      <div className="col-5">
                        <div className="main-img1">
                          <img src={d.img2} alt="" />
                        </div>
                        <div className="main-img2">
                          <img src={d.img3} alt="" />
                        </div>
                      </div>
                      <div className="col-7">
                        <div className="main-img3">
                          <img src={d.img4} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img className="shape1" src={shape1} alt="" />
              <img className="shape2" src={line1} alt="" />
              <img className="shape3" src={shape2} alt="" />
              <img className="shape4" src={line2} alt="" />
            </div>
          </SwiperSlide>
        ))}

        <div className="bottom-wrapper">
          <ul className="social-list">
            <li>
              <a
                href="https://www.facebook.com/quytranvankhe"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </li>
            <li>
              <a href="mailto: tranvankhefoundation@gmail.com" rel="noreferrer">
                Email
              </a>
            </li>
            {/* <li>
              <a href="https://www.instagram.com/" rel="noreferrer">
                Instagram
              </a>
            </li> */}
          </ul>
          {/* <div className="btn-wrapper">
            <div className="main-btn main-btn-prev" ref={navigationPrevRef}>
              <i className="fa fa-angle-left"></i>
            </div>
            <div className="main-btn main-btn-next" ref={navigationNextRef}>
              <i className="fa fa-angle-right"></i>
            </div>
          </div> */}
        </div>
      </Swiper>
      <DonateModal ref={modalRef} />
    </>
  );
};

export default Mainslider2;
